<template>
	<div class="vista" id="cotizacion">
		<div class="tab-content" id="myTabContent">
			<div class="" id="home" v-if="mostrarDatosCotizacion">
				<vx-card title="" class="nueva">
					<h4 class="titulo">Nueva cotización</h4>

          <form>
            <div class="row w-full">
              <div class="col-md-8">
                <span class="span-placeholder" for="lineaNegocio">Línea de negocio</span>
                <select
                  v-model="caso.PrecalificacionTipo" 
                  name="lineaNegocio" 
                  id="lineaNegocio" 
                  class="v-select vs--single  vs-input--input">
                  <option :value="tipo.Id" v-for="tipo in productoTipoList" :key="tipo.Id">{{ tipo.Nombre }}</option>
                </select>
              </div>
            </div>

            <div class="row w-full">
              <div class="col-md-8">
                <label class="span-placeholder" for="destinos">Destino</label>
                <select name="cars" id="destinos" v-model="caso.destino" class="vs-input" @click="factorySettings()" @change="mostrarProgramas()">
                  <option :value='tipo.Id' v-for="tipo in destinos"  >{{tipo.Nombre}}</option>
                </select>
              </div>
            </div>
            <div class="row w-full">
              <div class="col-md-8" v-if="(caso.destino == 1 || caso.destino == 5 || caso.destino == 2 || caso.destino == 20 || caso.destino == 19 || caso.destino == 15) && caso.PrecalificacionTipo == 1">
                <label class="span-placeholder" for="Programas">Programas</label>
                <select name="cars" id="Programas" v-model="caso.programa" class="vs-input">
                  <option :value='tipo.Id' v-for="tipo in programas"  >{{tipo.Nombre}}</option>
                </select>
              </div>
            </div>

            <Automotriz 
              :otro="otro"
              :destinoAuto="caso.destino"
              :porcentajeMaximo="PorcentajeMaximo"
              v-on:updateValores="update($event)"
              v-if="caso.PrecalificacionTipo == 3 && (caso.destino == 41 || caso.destino == 42 || caso.destino == 43 || caso.destino == 45 || caso.destino == 46 || caso.destino == 47)" />
            <AdquisicionCofi :programa="caso.programa" :Destino="caso.destino" :otro="otro" v-if="(caso.destino == 1 || caso.destino == 19) && (caso.programa == 4 || caso.programa == 9 ) && caso.PrecalificacionTipo == 1" v-on:updateValores="update($event)" v-on:buscaBancos="cambiaBancos($event)"/>
            <Adquisicion :otro="otro" v-if="(caso.destino == 1 || caso.destino == 24 || caso.destino == 21 || caso.destino == 3 || caso.destino == 27 || caso.destino == 15 || caso.destino == 8 || caso.destino == 10 || caso.destino == 32 || caso.destino == 37 ) && (caso.programa==1 || caso.programa==6) && caso.PrecalificacionTipo == 1" v-on:updateValores="update($event)" v-on:buscaBancos="cambiaBancos($event)" :porcentajeMaximo="PorcentajeMaximo" />
            <Mejora v-if="(caso.destino == 25 || caso.destino == 22 || caso.destino == 33 || caso.destino == 34 || caso.destino == 19) && caso.PrecalificacionTipo == 1" v-on:updateValores="update($event)" v-on:buscaBancos="cambiaBancos($event)"/>
            <TerrenoProgramas :programa="caso.programa" :otro="otro"  v-if="(caso.destino == 5 || caso.destino == 20 || caso.destino == 2)&& (caso.programa == 4 || caso.programa == 9 ) && caso.PrecalificacionTipo == 1" v-on:updateValores="update($event)" v-on:buscaBancos="cambiaBancos($event)"/>
            <Terreno :otro="otro" :destino="caso.destino"  v-if="(caso.destino == 5 || caso.destino == 20 || caso.destino == 2) && (caso.programa==1 || caso.programa==6) && caso.PrecalificacionTipo == 1" v-on:updateValores="update($event)" v-on:buscaBancos="cambiaBancos($event)" :porcentajeMaximo="PorcentajeMaximo" />
            
             <div
              v-if="caso.PrecalificacionTipo == 1" 
              class="row w-full">
              <div class="col-md-4">
                <span class="text-sm" for="gastosNotariales">Gastos notariales</span>
                <select name="gastosNotariales" id="gastosNotariales" v-model="caso.gastosNotarial" class="v-select vs--single  vs-input--input">
                  <option :value='tipo.value' v-for="tipo in gastosNotariales"  :key="">{{tipo.label}}</option>
                </select>
              </div>
            </div>

              <!-- Ministraciones -->
              <div class="row w-full" v-if="(caso.destino == 2 || caso.destino == 8 || caso.destino == 20 || caso.destino == 21 || caso.destino == 10 || caso.destino == 32) && caso.PrecalificacionTipo == 1">
                <div class="col-md-4">
                  <span 
                    class="text-sm" 
                    for="hasAportacion">Uso de Ministraciones
                  </span>

                  <div class="flex justify-between col-md-5 mt-2">
                    <div class="flex items-center">
                      <vs-radio 
                        v-model="usoMinistraciones" 
                        vs-name="usoMinistraciones" 
                        vs-value="No"></vs-radio>

                      <span class="span-placeholder">No</span>
                    </div>
                    <div class="flex items-center">
                      <vs-radio 
                        v-model="usoMinistraciones" 
                        vs-name="usoMinistraciones" 
                        vs-value="Si"></vs-radio>

                      <span class="span-placeholder">Si</span>
                    </div>
                  </div>
                </div>
              </div>
              <div  v-if="usoMinistraciones== 'Si'">
                <div class="row w-full">
                  <div class="col-md-4">
                    <span class="span-placeholder" for="destinos">Plazo de construcción (meses)</span>
                    <select name="cars" id="plazoContruccion" v-model="plazoContruccion" class="vs-input" @change="updatePeriodo()">
                      <option :value="plazo.value" v-for="plazo in plazoMinistraciones" :key="">{{ plazo.label }}</option>
                    </select>
                  </div>									
                  <div class="col-md-4">
                    <span class="span-placeholder" for="destinos">Periodo de ministraciones</span>
                    <select name="cars" id="periodo" v-model="periodo" class="vs-input" @change="updateCampos()">
                      <option :value="periodo.value" v-for="periodo in periodoMinistraciones" :key="">{{ periodo.label }}</option>
                    </select>
                  </div>
                </div>
                <div class="row w-full">
                  <div class="col-md-4" v-if="campos">
                    <span class="span-placeholder" for="destinos">Porcentaje de ministraciones</span><br>
                  </div>											
                </div>
                <div class="row w-full" v-if="campos">
                  
                  <!--<div class="flex items-center" id="porcentajesMinistracion"> -->
                  <div class="col-md-1 flex items-center" id="porcentajesMinistracion" v-for="item in campos">

                    <div id="inputFormRow" v-if="item <= 9">
                        <span class="span-placeholder" for="destinos"> {{ item }}</span>
                      <div class="flex items-center">												
                        <input type="text" v-model="camposID.name[item]" class="form-control" v-on:blur="calculaPorcentaje()" style="padding-left:10%;" v-on:keypress="SoloNumero($event)"> %'
                      </div>			
                    </div>																																				
                  </div>
                  
                </div>
                <div class="row w-full" v-if="campos">
                  <div class="col-md-1 flex items-center" id="porcentajesMinistracion2" v-for="item in campos"  v-if="item > 9">
                    <div id="inputFormRow">
                        <span class="span-placeholder" for="destinos"> {{ item }}</span>
                      <div class="flex items-center">												
                        <input type="text" v-model="camposID.name[item]" class="form-control" v-on:blur="calculaPorcentaje()" style="padding-left:10%;" v-on:keypress="SoloNumero($event)"> %'
                      </div>			
                    </div>																							
                  </div>
                </div>
                <span id="alert" class="text-danger span-placeholder" style="display: none; color: red; ">La suma de los porcentajes de ministraciones tiene que ser 100%</span>
              </div>
                          
            <div class="row w-full">
              <div class="col-md-8">
                <label class="span-placeholder">Bancos</label>
                <v-select v-model="caso.bancos" multiple :closeOnSelect="false" :options="bancos" :dir="$vs.rtl ? 'rtl' : 'ltr'"/>
              </div>

            </div>
           <div class="row w-full  ">
              <div class=" col-md-8 align-blue">
              <vs-button class="btn-blue" @click.prevent="seleccionarTodos()" >Seleccionar todo</vs-button>
              
              <vs-button class="btn-blue" @click.prevent="limpiarBancos()" >Limpiar Selección</vs-button>
              </div>
        
            </div>
            <div class="row w-full">
              <div
                v-if="caso.PrecalificacionTipo == 1"
                class="col-md-4">
                <label class="span-placeholder" for="estatus">Perfil de cliente</label>
                <select name="cars" id="tasas" v-model="caso.tasa" class="vs-input">
                  <option :value='tipo.Id' v-for="tipo in tasas"  >{{tipo.Nombre}}</option>
                </select>
              </div>
             <div class="col-md-4">
                <span class="span-placeholder" for="estados">Estado</span>
                <!-- <select name="cars" id="estados" v-model="caso.estado" class="vs-input" @change="mostrarProgramas()"> -->
                <select name="cars" id="estados" v-model="caso.estado" class="vs-input">
                  <option :value='tipo.Id' v-for="tipo in estados"  >{{tipo.Nombre}}</option>
                </select>
              </div>
            </div>

            <!-- aportaciones voluntarias -->
            <div 
              v-if="caso.PrecalificacionTipo == 1"
              class="row w-full">
              <div class="col-md-4">

                <span 
                  class="text-sm" 
                  for="hasAportacion">Aportaciones voluntarias
                </span>

                <div class="flex justify-between col-md-5 mt-2">

                  <div class="flex items-center">
                    <vs-radio 
                      v-model="hasAportacion" 
                      vs-name="hasAportacion" 
                      vs-value="No"></vs-radio>
                    <span class="span-placeholder">No</span>
                  </div>

                  <div class="flex items-center">
                    <vs-radio 
                      v-model="hasAportacion" 
                      vs-name="hasAportacion" 
                      vs-value="Si"></vs-radio>
                    <span class="span-placeholder">Si</span>
                  </div>

                </div>

              </div>
            </div>

            <!-- campos ocultos -->
            <div
              v-if="hasAportacion == 'Si' && caso.PrecalificacionTipo == 1" 
              class="row w-full">

              <div class="col-md-3">
                <span class="span-placeholder" for="tipoPrepago">Tipo prepago</span>
                <select 
                  v-model="tipoPrepago"
                  name="tipoPrepago" 
                  id="tipoPrepago" 
                  class="vs-input">
                  <option :value="''">-- Seleccione --</option>
                  <option 
                    :value='prepago.Id' 
                    v-for="prepago in prepagoList">{{ prepago.Nombre }}
                  </option>
                </select>
              </div>

              <div class="col-md-3">
                <span class="span-placeholder" for="aportacionVoluntaria">Aportación voluntaria</span>
                <div>
                  <span class="symbol-currency col-12">
                    <input 
                      v-model="aportacionVoluntaria"
                      type="text"
                      v-on:keyup="checkApoVol()"
                      @change="setFormatApoVol()"
                      v-on:keypress="isNumber($event)"
                      class="vs-input input-currency"
                      id="aportacionVoluntaria"
                      name="aportacionVoluntaria"/>
                  </span>
                </div>
              </div>

            </div>

            <div
              v-if="hasAportacion == 'Si' && caso.PrecalificacionTipo == 1"
              class="row w-full">

              <div class="col-md-3">
                <span class="span-placeholder" for="tipoPlazo">Plazo</span>
                <select 
                  v-model="tipoPlazo"
                  @change="setPlazoAportacion()"
                  name="tipoPlazo" 
                  id="tipoPlazo" 
                  class="vs-input">
                  <option :value="''">-- Seleccione --</option>
                  <option 
                    :value='plazo.Id' 
                    v-for="plazo in plazosList">{{ plazo.Nombre }}
                  </option>
                </select>
              </div>

              <div class="col-md-3">
                <span class="span-placeholder" for="numeroAportacion">Número de aportaciones</span>
                <input 
                  v-model="numeroAportacion"
                  type="text"
                  class="vs-input"
                  id="numeroAportacion"
                  name="numeroAportacion"/>
                  
                <span 
                  name="numAportaHelper"
                  id="numAportaHelper"
                  class="text-danger span-placeholder">
                  {{ helperCantidadAportacion }}
                </span>
              </div>

            </div>
            <!-- aportaciones voluntarias -->


            <br>
            <button class="btn btn-second" @click.prevent="registrarCaso()" :disabled="!validateForm">Cotizar</button>
            
        </form>
        </vx-card>
  </div>
  <div class="opciones" id="profile" v-if="mostrarOpciones">
    <div class="excel">
          <button  class="btn btn-exportar mt-4" @click="regresarAcotizacion()" >
            <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.334 11.675L12.148 14.861L15.334 18.047" stroke="#26558F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M24.892 14.861L12.234 14.861" stroke="#26558F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path opacity="0.4" d="M14.9349 24.892C9.43494 24.892 4.97794 21.158 4.97794 14.935C4.97794 8.71201 9.43493 4.97801 14.9349 4.97801" stroke="#26558F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg><strong>Regresar</strong>
          </button>
      </div>
    <vx-card title="" class="" v-if="items.length">
          <div class="d-grid gap-2 d-md-flex justify-content-md-start">
            <h4 class="titulo ">Opciones de crédito</h4>
            <button  class="btn btn-success" @click="mostrarFormSolicitante" >Guardar</button>
          </div>
          <div class="">
            <p class="text-grey mb-0" id="title" v-if="caso.programa==9 || caso.programa==4">Resumen de crédito</p>
           <span v-if="caso.programa==9 || caso.programa==4">Valor inmueble:{{caso.valorInmueble}}</span>
           <br v-if="caso.programa==9 || caso.programa==4">
            <span v-if="caso.programa==9 || caso.programa==4">Monto de crédito:{{caso.montoCredito}}</span>
              <div class="btn-fav">
                <svg class="heart fav-heart">
                  <path id="Vector" d="M19.855,0A7.63,7.63,0,0,0,13.75,3.066,7.634,7.634,0,0,0,0,7.686a14.683,14.683,0,0,0,.715,4.551C2.887,19.112,9.584,23.224,12.9,24.351a3,3,0,0,0,1.7,0c3.314-1.128,10.01-5.239,12.182-12.114A14.683,14.683,0,0,0,27.5,7.686,7.664,7.664,0,0,0,19.855,0Z" transform="translate(2.75 4.263)"/>
                </svg>
                <div class="fav-font cursor-pointer" @click="irImprimir()" v-if="caso.favoritos.length >0">Ver Favoritos ({{caso.favoritos.length}})</div> 
                <div class="fav-font cursor-pointer"  v-if="caso.favoritos.length==0">Ver Favoritos ({{caso.favoritos.length}})</div>
              </div>
            <!--<br>
            <p class="text-grey mb-0">Ordenar por</p>
            <div class="flex justify-between">
              <ul class="centerx vx-row filtros" v-if="caso.destino != 19 || caso.destino != 25 || caso.destino != 22 || caso.destino != 33 || caso.destino != 34">
                <li v-for="filtro in filtros"  :key="filtro.value" style="margin:2%;">
                  <vs-radio v-model="filtroModel" :vs-value="filtro.value" @change="filtrar(filtro.value)" v-if="filtro.value < 7">{{filtro.label}}</vs-radio>
                </li>
              </ul>
              <ul class="centerx vx-row filtros" v-else>
                <li v-for="filtro in filtros"  :key="filtro.value" style="margin:2%;">
                  <vs-radio v-model="filtroModel" :vs-value="filtro.value" @change="filtrar(filtro.value)">{{filtro.label}}</vs-radio>
                </li>
              </ul>
            </div>-->
					</div>
					<div id="cotizacion">
						<div class="opciones">
							<div class="row w2-full">
								<div class="thead-productos">
									<div class="col-md-2 banco-logo"></div>
									<div class="col-md-3 descripcion">Descripción</div>
									<div class="col-md-1 informacion"></div>
									<div class="col-md-2 ordenar">
										<span class="ordenar-titulo">Ordenar por<span class="ocultar">:</span></span>
										<div class="">
											<div class="" v-if="destino == 19 || destino == 25 || destino == 22 || destino == 33 || destino == 34">
												<select v-model="filtroModel" @change="filtrar()" class="vs-ordenar">
													<option :value="filtro.value" v-for="filtro in filtrosComplet" :key="filtro.value">{{ filtro.label }}</option>
												</select>
											</div>
											<div class="" v-else>
												<select v-model="filtroModel" @change="filtrar()" class="vs-ordenar">
													<option :value="filtro.value" v-for="filtro in filtros" :key="filtro.value">{{ filtro.label }}</option>
												</select>
											</div>
										</div>
									</div>
									<div class="col-md-2 acciones">Acciones</div>
									<div class="col-md-1 promociones">Promociones</div>
									<div class="col-md-1 producto"></div>
								</div>
							</div>
							<div class="relative">
								<div class="items-list-view" v-for="(item, index) in items" :key="item.objectID">
									<Productos :item="item"  :destino="caso.destino" :filtroName="filtroName" :filtroId="filtroId" :idCaso="id" class="" :ministraciones="usoMinistraciones" :plazoMinistracion="plazoContruccion">
										<template slot="mejora" v-if="caso.destino == 19 || caso.destino == 25 || caso.destino == 22 || caso.destino == 33 || caso.destino == 34">
								<!--<p class="text-md" style="color: green">Ahorro Mensual ${{ numberFormat(item.MejoraMensualidad) }}</p>
											<p class="text-md" style="color: green">Ahorro Total ${{ numberFormat(item.AhorroTotal > 0 ? item.AhorroTotal : 0) }}</p>-->
										</template>

										<template slot="cofinavit" v-if="destino == 1 && caso.programa == 4 && item.GastosInicialesCofinavit">
											<td class="td-modal">Gastos Iniciales con Cofinanciamiento Infonavit:</td>
											<td>${{ numberFormat(item.GastosInicialesCofinavit) }}</td>
										</template>
										<template slot="gastos" v-else>
											<td class="td-modal">Gastos Iniciales:</td>
											<td>${{ numberFormat(item.GastosIniciales) }}</td>
										</template>

										<template slot="action-buttons-2">
											<!-- <vx-tooltip text="Favoritos">
												<button class="favoritos" @click.prevent="agregarFavorito(item)" v-if="comprobarFavorito(item)">
													<svg class="heart">
														<path id="Vector" d="M19.855,0A7.63,7.63,0,0,0,13.75,3.066,7.634,7.634,0,0,0,0,7.686a14.683,14.683,0,0,0,.715,4.551C2.887,19.112,9.584,23.224,12.9,24.351a3,3,0,0,0,1.7,0c3.314-1.128,10.01-5.239,12.182-12.114A14.683,14.683,0,0,0,27.5,7.686,7.664,7.664,0,0,0,19.855,0Z" transform="translate(2.75 4.263)" />
													</svg>
												</button>
												<button class="favoritos" icon-pack="feather" @click.prevent="quitarFavorito(item)" v-else><feather-icon icon="HeartIcon" :svgClasses="[{ 'text-danger fill-current': !comprobarFavorito(item) }, 'h-8 w-8']" /></button>
											</vx-tooltip> -->

                      <vx-tooltip :text="comprobarFavorito(item) && isLimitFavoritos ? 'Solo se pueden marcar 5 favoritos como máximo' : 'Favoritos'">
                        <button class="favoritos" :disabled="isLimitFavoritos" @click.prevent="agregarFavorito(item)" v-if="comprobarFavorito(item)">
                          <svg class="heart" v-bind:class="{ 'disabled': isLimitFavoritos }">
                            <path id="Vector" d="M19.855,0A7.63,7.63,0,0,0,13.75,3.066,7.634,7.634,0,0,0,0,7.686a14.683,14.683,0,0,0,.715,4.551C2.887,19.112,9.584,23.224,12.9,24.351a3,3,0,0,0,1.7,0c3.314-1.128,10.01-5.239,12.182-12.114A14.683,14.683,0,0,0,27.5,7.686,7.664,7.664,0,0,0,19.855,0Z" transform="translate(2.75 4.263)" />
                          </svg>
                        </button>
                        <button class="favoritos" icon-pack="feather" @click.prevent="quitarFavorito(item)" v-else>
                          <feather-icon icon="HeartIcon" :svgClasses="[{ 'text-danger fill-current': !comprobarFavorito(item) }, 'h-8 w-8']" />
                        </button>
                      </vx-tooltip>
										</template>
									</Productos>
								</div>
							</div>
						</div>
					</div>
				</vx-card>
				<vs-alert active="true" v-else> No hay resultados que coincidan con su consulta </vs-alert>
			</div>
			<div v-if="mostrarInfoSolicitante">
				<vx-card title="">
					<h4 class="titulo">Opciones de crédito</h4>
					<div class="row w-full">
            <div class="col-md-3">
							<label class="span-placeholder">Correo electrónico</label>
							<input class="vs-input" v-model="email" v-validate="'required|email'" name="email" />
							<!-- <span class="text-danger text-sm" v-show="errors.has('email')">Campo requerido, debe ser un email válido</span>    -->
						</div>
						<div class="col-md-3">
							<label class="span-placeholder">Nombre</label>
							<input class="vs-input" v-validate="'required|alpha_spaces'" maxlength="20" v-model="nombre" name="nombres" />
							<!--<span class="text-danger text-sm" v-show="errors.has('nombres')">Campo requerido, solo caracteres</span>-->
						</div>
						<div class="col-md-3">
							<label class="span-placeholder">Segundo nombre</label>
							<input class="vs-input" v-validate="'alpha_spaces'" maxlength="20" v-model="segundoNombre" name="segundoNombre" />
							<!--<span class="text-danger text-sm" v-show="errors.has('segundoNombre')">Campo requerido, solo caracteres</span>-->
						</div>
						<div class="col-md-3">
							<label class="span-placeholder"> Apellido Paterno</label>
							<input class="vs-input" v-validate="'required|alpha_spaces'" maxlength="20" v-model="apellidoPaterno" name="apellidoPaterno" />
							<!--<span class="text-danger text-sm" v-show="errors.has('apellidoPaterno')">Campo requerido, solo caracteres</span> -->
						</div>
					</div>
					<div class="row w-full">
            <div class="col-md-3">
							<label class="span-placeholder"> Apellido Materno</label>
							<input class="vs-input" v-validate="'alpha_spaces'" maxlength="20" v-model="apellidoMaterno" name="apellidoMaterno" />
							<!-- <span class="text-danger text-sm" v-show="errors.has('apellidoMaterno')">Campo requerido, solo caracteres</span> -->
						</div>
						<div class="col-md-3">
							<label class="span-placeholder">Celular (10 dígitos)</label>
							<input class="vs-input" v-model="telefono" name="telefono" maxlength="10" v-validate="'numeric|min:10|max:10'" v-on:keypress="isNumber($event)" />
							<!--<span class="text-danger text-sm" v-show="errors.has('telefono')">El campo debe tener 10 caracteres</span>-->
						</div>
					</div>
				</vx-card>

          <!--MEJORA CURP-->
          <vx-card class="liga_cliente">
            <div class="card__header"><span>Liga del cliente</span></div>
            <div class="row w-full">
              <div class="col-md-3">
                <span class="span-placeholder">CURP</span>
                <input class="vs-input" v-model="curp" name="curp" v-validate="'min:18|max:18'" v-mask="'AAAA######AAAAAANN'" value=""  onkeyup="javascript:this.value=this.value.toUpperCase();"/>
                <span class="text-danger span-placeholder" v-show="errors.has('curp')">El campo debe tener 18 caracteres</span>
              </div> 
              <div class="col-md-9"><br>
                <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                  <button class="btn btn-primary" @click="regresar()">Regresar</button>
                  <button class="btn btn-secondary" @click="ValidaSolicitante()" :disabled="!validar" id="btnGuardar">Guardar</button>
                </div>
              </div>
            </div>
            <div class="row w-full">
              <div class="col-md-12">
                <p>La liga es un enlace que se le envía al cliente para que este pueda proporcionar todos los datos necesarios para crear su pedido en SISEC, subir documentos pendientes y poder visualizar el estatus de su pedido.</p>
                <p><strong>*si desea que esta liga se le envíe al cliente favor de llenar el campo de la CURP.</strong></p>
              </div>
            </div>
          </vx-card>
			</div>
			<div class="modal fade" id="modalTabla" ref="table" size="sm" hide-footer hide-header>
				<div class="modal-dialog modal-dialog-centered">
					<div class="modal-content">
						<div class="modal-body">
							<div class="row">
								<div class="d-md-flex justify-content-md-center"><h1 style="color: #00368c">Folio de simulación</h1></div>
							</div>
							<div class="d-md-flex justify-content-md-center" id="icono"></div>
							<div class="row">
								<div class="d-md-flex justify-content-md-center">
									<h1 style="color: #00368c">{{ id }}</h1>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Productos from '@/views/Caso/Productos.vue'
import Automotriz from '@/views/Destinos/Automotriz.vue';
import Adquisicion from '@/views/Destinos/Adquisicion.vue'
import Mejora from '@/views/Destinos/Mejora.vue'
import AdquisicionCofi from '@/views/Destinos/AdquisicionCofi.vue'
import AdquisicionAliados from '@/views/Destinos/AdquisicionAliados.vue'
import Terreno from '@/views/Destinos/Terreno.vue'
import TerrenoConstruccion from '@/views/Destinos/TerrenoConstruccion.vue'
import TerrenoProgramas from '@/views/Destinos/TerrenoProgrmas.vue'
import vSelect from 'vue-select'

export default {
  components: {
    Productos,
    Automotriz,
    Adquisicion,
    Mejora,
    AdquisicionCofi,
    AdquisicionAliados,
    Terreno,
    TerrenoProgramas,
    TerrenoConstruccion,
    'v-select': vSelect,
  },
  data(){
		return{
      autosDestinos: [],
      porcentajeActivaCotiza: true,
      tokenSisec: '',
			// aportacion voluntaria
      hasAportacion: "No",
      plazosList: [],
      prepagoList: [],
      tipoPrepago: '',
      aportacionVoluntaria: '',
      tipoPlazo: '',
      numeroAportacion: '',
      helperCantidadAportacion: '',
			// aportacion voluntaria


      factorPorcentualSantander: {
				aforoTipoA: [ // 5-50% 
					{ plazo: 10, porcentaje: 1.1706 },
					{ plazo: 15, porcentaje: 1.6414 },
					{ plazo: 20, porcentaje: 2.0394 },
				],
				aforoTipoB: [ // 55-70% 
					{ plazo: 10, porcentaje: 1.3439 },
					{ plazo: 15, porcentaje: 1.8819 },
					{ plazo: 20, porcentaje: 2.3396 },
				],
				aforoTipoC: [ // 75%  
					{ plazo: 10, porcentaje: 1.5161 },
					{ plazo: 15, porcentaje: 2.1227 },
					{ plazo: 20, porcentaje: 2.6401 },
				],
				aforoTipoD: [ // 80% 
					{ plazo: 10, porcentaje: 1.4747 },
					{ plazo: 15, porcentaje: 2.0654 },
					{ plazo: 20, porcentaje: 2.5683 },
				],
				aforoTipoE: [ // 85% 
					{ plazo: 10, porcentaje: 1.6387},
					{ plazo: 15, porcentaje: 2.2947 },
					{ plazo: 20, porcentaje: 2.8542 },
				],
				aforoTipoF: [ // 90% 
					{ plazo: 10, porcentaje: 1.9051 },
					{ plazo: 15, porcentaje: 2.6924 },
					{ plazo: 20, porcentaje: 3.4094 },
				],
			},

      id:'',
      items:[],
      destino:1,
      destinos:[],
     // bancosEjecutivo:[],
      bancosEjecutivo:[{label:'CITIBANAMEX',value:2},
                      {label:'BANORTE',value:4},
                      {label:'HSBC',value:6},
                      {label:'SANTANDER',value:10},
                      {label:'SCOTIABANK',value:11}],
      bancos:[],
      bancos2:[],
      programas:[],
      tasas:[],
      productoTipoList: [],
      caso:{
        valor:'',
        credito:'',
        destino:1,
        plazos:[],
        esquemas:[],
        plazoRestante:'',
        mensualidad:'',
        programa:1,
        bancos:[],
        tasa:2,
        favoritos:[],
        ssv:'',
        creditoInf:'',
        gastosNotarial:6,
        montoCredito:0,
        estado:'',
        PrecalificacionTipo: '',
        aniosACotizar: '',
        VersionOrigen: '',
      },
			bancosAuto: [
				{ label: "HSBC", value: 6 },
				{ label: "SCOTIABANK", value: 11 },
				{ label: "AFIRME", value: 12 },
				{ label: "HEY, BANCO", value: 14 },
			],
      filtroModel:1,
      filtroName:'',
      filtroId:'',
      filtros:[
        { label: 'Mensualidad Inicial',  value: '1' },
        { label: 'Alfabético', value: '9' },
        { label: 'Cat',  value: '2' },
        { label: 'Tasa',  value: '3' },
        { label: 'Pago total',  value: '4' },
        { label: 'Con promociones',  value: '5' },
        { label: 'Mayor ahorro total',  value: '7' },
        { label: 'Mayor ahorro mensual',  value: '8' },
        { label: 'Ingreso Minimo',  value: '6' },
      ],
      gastosNotariales:[{label : '1 %', value : '1'},
                        {label : '2 %', value : '2'},
                        {label : '3 %', value : '3'},
                        {label : '4 %', value : '4'},
                        {label : '5 %', value : '5'},
                        {label : '6 %', value : '6'},
                        {label : '7 %', value : '7'},
                        {label : '8 %', value : '8'},
                        {label : '9 %', value : '9'},
                        {label : '10 %', value : '10'}
      ],
      otro:0,
      estados:[],
      mostrarInfoSolicitante:false,
      mostrarOpciones:false,
      mostrarDatosCotizacion:true,
      nombre:'',
      segundoNombre:'',
      apellidoPaterno:'',
      apellidoMaterno:'',
      telefono:'',
      email:'',
      curp:'',
      emailEjecutivo:'',
      mensajeModal:'',  
      // header: {
      //     "authorization": "Bearer 16JXvQ9CD1eEoVESY3-4d-bElrAAgAfcB0UgYwAB-XyuNugkcBvgntr6n6VjEAFWenS9Exi_jlLv_RBfmf004TXytHP64dNEuSyhlXOBDtO56hW07txWd4evNWfukK5CO1ETRIOk8GWyjjS7daQ8eLHUz3XefoKEHKE0yIy76o8rZZYTGskAn1Zo3oKcPFzfs_fmVlO15cD_Kxx27-1qqDBsfAphhY7N599ly5ppo5L4XHaL8JAv6mTxXVYxrTZPQDmnLqQ9Nwre7Bd4URM5rR3rVMrAVkPzJ8ohQ9-pgvigB-Fra_SMi31c5T-QrSTB"
      //   },
      SocGuid: '',
      BrokerId: '',
      IdLiga: '',
      GastoNotarialItem:0,
      ValorInmuebleKredi:'',
			EngancheKredi:'',
			PorcentajeEngancheKredi:'',
			PlazosKredi:'',
			FinanciamientoKredi:'',
			ComisionAperturaKredi:'',
		    EstadoKredi:'',
			GastosNotarialesKredi:'',
      Programa: 1,
      // MINISTRACIONES EN COMPARADOR - JORGE SEP 2023
        usoMinistraciones: 'No',
        plazoContruccion: '',
        plazoMinistraciones: [
          {label: '-- Seleccione --', value: ''},
          {label: '3', value: '3'},
          {label: '4', value: '4'},
          {label: '5', value: '5'},
          {label: '6', value: '6'},
          {label: '7', value: '7'},
          {label: '8', value: '8'},
          {label: '9', value: '9'},
          {label: '10', value: '10'},
          {label: '11', value: '11'},
          {label: '12', value: '12'},
          {label: '13', value: '13'},
          {label: '14', value: '14'},
          {label: '15', value: '15'},
          {label: '16', value: '16'},
          {label: '17', value: '17'},
          {label: '18', value: '18'}
        ],
        periodo: '',
        periodoMinistraciones: [
        {label: '-- Seleccione --', value: ''},			
        ],
        campos: '',
        campo_1: 10,
        camposID: {
          name: []
        },
        banderaSave: false,
 //     Programa: '',
      PorcentajeMaximo: 0,
			isLimitFavoritos: false
    }
  
  },
  watch: {
		// Formato mayusculas sin acento
		nombre: function(newValue, oldValue) {
			this.nombre = this.textFormat(newValue);
		},
		segundoNombre: function(newValue, oldValue) {
			this.segundoNombre = this.textFormat(newValue);
		},
		apellidoPaterno: function(newValue, oldValue) {
			this.apellidoPaterno = this.textFormat(newValue);
		},
		apellidoMaterno: function(newValue, oldValue) {
			this.apellidoMaterno = this.textFormat(newValue);
		},
    'caso.PrecalificacionTipo': function(newValue, oldValue) {
			// console.log( 'productoTipo', newValue );
			this.getDestinos(newValue);
		},
    // INFO: Sin dependencia de plazos de credito
    numeroAportacion: function(newValue, oldValue) {
      // console.log( 'newValue: ', newValue, 'oldValue: ', oldValue, this.numeroAporacion );

      if (this.hasAportacion == "Si") {

        // verifico si es un numero
        let regex = /^[0-9]*$/;
        if (!regex.test(newValue)) {
          this.numeroAportacion = oldValue;
          // console.log( 'er', this.numeroAportacion );
        }

        // valida el plazo de credito y el plazo de aportacion
        if (this.caso.plazos.length > 0 && this.tipoPlazo != '') {

          if (!this.numeroAportacion) {
            this.helperCantidadAportacion = `Escriba un valor correcto`;
            return;
          }

          // plazo mas grande
          let plazo = Math.max(...this.caso.plazos);
          let periodo = this.tipoPlazo;
          let limiteAportacion = 0;

          // calcula el limite
          if (plazo > 0 && periodo > 0) {
            switch (periodo) {
              case 1:
                // mensual
                limiteAportacion = 12 * plazo;
                break;
              case 2:
                // bimestral
                limiteAportacion = (12 / 2) * plazo;
                break;
              case 3:
                // semestral
                limiteAportacion = (12 / 6) * plazo;
                break;
              case 4:
                // anual
                limiteAportacion = plazo;
                break;
              }
          }

          // limita el valor
          if ( newValue > limiteAportacion) {
            this.numeroAportacion = '';
          }

          // mensaje
          this.helperCantidadAportacion = `Tú límite de aportaciones es ${ limiteAportacion }`;
          return;
        }

        // console.log( 'falta credito/plaza', typeof(this.numeroAportacion), this.numeroAportacion );
        this.helperCantidadAportacion = `Definir plazo de aportación y plazo a cotizar`;
        this.numeroAportacion = ''; // reinicia el valor

      }
    },
    hasAportacion: function(newValue, oldValue) {
      // reinicia el valor de los campos de aportacion
      if (newValue == "No") {
        this.tipoPrepago = '';
        this.aportacionVoluntaria = '';
        this.tipoPlazo = '';
        this.numeroAportacion = '';
        this.helperCantidadAportacion = '';
      }
    },
    'caso.destino': function(n, o) {
      // console.log( 'DESTINO watch', n );
      if (this.autosDestinos.includes(n)) {
        this.getBancosAutoDestino();
      }
    }
  },
  computed:{
    validateForm(){
      if (this.usoMinistraciones == 'Si') {        
        return  this.caso.estado != ''    &&
                this.validateAportacion   &&
                this.banderaSave &&
                this.porcentajeActivaCotiza
      }else{
        return  this.caso.estado != '' &&
              this.validateAportacion &&
              this.porcentajeActivaCotiza
      }      
    },
    validateAportacion() {
      if (this.hasAportacion == "Si") {
        return this.tipoPrepago != '' &&
               this.aportacionVoluntaria != '' &&
               this.tipoPlazo != '' &&
               this.numeroAportacion != ''
      }
      return true;
    },
    emailState() {
      const regex = /^[-\wñ.%+]{1,64}@(?!socasesores)(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i ///^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
      const matches = regex.test(this.email);
      return this.email == null || matches ? true:false
    },
    celphoneState() {
      return this.telefono == null || this.telefono.length == 10 ? true : false
    },
    nameState() {
      return   this.nombre==null || this.nombre.length >= 3 ? true : false
    },
    lastNameState() {
      return this.apellidoPaterno == null || this.apellidoPaterno.length > 2 ? true : false
    },
     /* emailState() {
      const regex = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i ///^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
      const matches = regex.test(this.correo);
      return this.correo == null || matches ? true:false
    },*/
       validar(){
           return this.emailState && this.lastNameState && this.nameState && this.celphoneState 
       }
  },
  mounted(){
    this.getApikey()
    this.getDestinosAuto();
    this.getProductoTipos();
    // this.getDestinos()
    this.getProgramas()
    
    this.getTasas()
    //this.getBancosEjecutivo()
    this.getEstados()
    if(localStorage.getItem('caso')){
      this.id=localStorage.getItem('caso')
      this.dameCaso()
    }
    if(localStorage.getItem('regreso')){
      if (localStorage.getItem('caso')) {
        this.cotiza(this.id)
        //this.updateCaso(localStorage.getItem('caso'))
        //this.id=localStorage.getItem('caso')
        //this.dameCaso()
      }
    }
    //alert(this.bancosEjecutivo);
    //this.GeneraLiga()
    this.getBancos()
    this.DameCredenciales()
    
    this.getPrepagoList()
    this.getPlazoAportacionList()
    this.actualizaCampos()

    this.getDataDestino()

    this.caso.PrecalificacionTipo = 1;
  },
  methods:{
    // setBancosAutoDestino() {
    //   if (this.autosDestinos.includes(this.caso.destino)) {
    //     this.getBancosAutoDestino();
    //   }
    // },
    getBancosAutoDestino() {
      let self = this;
			let objRequestBancosAutoDestino = {
				strApiKey: this.key,
				strMetodo: 'ListaBancosAutoDestino',
				objCaso: {
          Destino: this.caso.destino,
				},
			}
			this.$axios
        .post('/', objRequestBancosAutoDestino, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
        .then((response) => {
					if (response.data.intEstatus == 0) {
            this.bancos = [];
            response.data.objContenido.forEach(banco => {
              this.bancos.push({ label: banco.Nombre, value: banco.Id })
            });
            this.caso.bancos = this.bancos;
          } else {
						this.$vs.notify({
							title: 'Ocurrio un error en ListaBancosAutoDestino',
							text: response.data.strError,
							color: 'danger',
							position: 'top-right',
						})
					}
				})
				.catch(function (error) {
					self.$vs.notify({
						title: 'Ocurrio un error en ListaBancosAutoDestino',
						text: error,
						color: 'danger',
						position: 'top-right',
					})
				})
    },
    getDestinosAuto() {
      let self = this
      let objRequestListaDestinosAuto = {
        strApiKey: this.key,
        strMetodo: 'ListaDestinosAuto',
      }
      this.$axios.post('/', objRequestListaDestinosAuto, {headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            let objAutos = response.data.objContenido;
            objAutos.forEach(objAuto => {
              this.autosDestinos.push(objAuto.Id);
            });
          } else {
            this.$vs.notify({
              title: 'Ocurrio un error en ListaDestinosAuto',
              text: response.data.strError,
              color: 'danger',
              position: 'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title: 'Ocurrio un error de sistema',
          text: error,
          color: 'danger',
          position:'top-right'
        })
      })
    },
		// Conversion
		textFormat(strValue) {
			return strValue.normalize('NFD')
							.replace(/([^n\u0300-\u036f]|n(?!\u0303(?![\u0300-\u036f])))[\u0300-\u036f]+/gi,"$1")
							.normalize()
							.toUpperCase(); // Eliminar acentos menos Ññ
		},
    getProductoTipos() {
			let self = this;
			let objRequestProductoTipos = {
				strApiKey: this.key,
				strMetodo: 'DameProductoTipos',
			}
				
			this.$axios.post(
				'/', 
				objRequestProductoTipos, 
				{ headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } }
			).then((response) => {
				if (response.data.intEstatus == 0) {
					let productoList = response.data.objContenido;
					// retirar pyme, tarjeta, personal
					productoList.splice(1,1);
					productoList.splice(2,2);
					productoList[1].Nombre = "Auto";
					this.productoTipoList = productoList;
				} else {
					this.$vs.notify({
						title: 'Ocurrio un error en DameProductoTipos',
						text: response.data.strError,
						color: 'danger',
						position: 'top-right',
					})
				}
			}).catch(function (error) {
				self.$vs.notify({
					title: 'Ocurrio un error en DameProductoTipos',
					text: error,
					color: 'danger',
					position: 'top-right',
				})
			})
		},
    async getTokenSisec() {
      let self = this;
      let objRequestToken = {
        strApiKey: this.key,
        strMetodo: 'DameAuthToken',
      }
      await this.$axios.post('/', objRequestToken,
        { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
        .then( response => {
          if (!response.data.error) {
            this.tokenSisec = response.data.access_token;
            // console.log( 'NuevaCotizacion' + this.tokenSisec );
          } else {
            this.$vs.notify({
              title: 'Ocurrio un error al obtener token',
              text: response.data.error_description,
              color: 'danger',
              position: 'top-right',
            })
          }
        }).catch(function (error) {
          self.$vs.notify({
            title: 'Ocurrio un error de sistema',
            text: error,
            color: 'danger',
            position: 'top-right',
          })
        });
    },
    //Ministraciones
		updatePeriodo(){		
			this.periodo = ''
			this.campos = ''
			document.getElementById('alert').style.display = 'none'

			if (this.plazoContruccion < 5) {
				this. periodoMinistraciones =  [
					{label: '-- Seleccione --', value: ''},
					{label : 'Mensual', value: 1},
					{label : 'Bimestral', value: 2},
					{label : 'Trimestral', value: 3},
				]
			}else if(this.plazoContruccion < 7){
				this.periodoMinistraciones= [
					{label: '-- Seleccione --', value: ''},
					{label : 'Mensual', value: 1},
					{label : 'Bimestral', value: 2},
					{label : 'Trimestral', value: 3},
					{label : 'Cuatrimestral', value: 4},					
				]
			}else if(this.plazoContruccion < 13){
				this.periodoMinistraciones = [
					{label: '-- Seleccione --', value: ''},
					{label : 'Mensual', value: 1},
					{label : 'Bimestral', value: 2},
					{label : 'Trimestral', value: 3},
					{label : 'Cuatrimestral', value: 4},
					{label : 'Semestral', value: 6},
					
				]
			}else{
				this.periodoMinistraciones = [
					{label: '-- Seleccione --', value: ''},
					{label : 'Mensual', value: 1},
					{label : 'Bimestral', value: 2},
					{label : 'Trimestral', value: 3},
					{label : 'Cuatrimestral', value: 4},
					{label : 'Semestral', value: 6},
					{label : 'Anual', value: 12}
				]
			}
		},

		updateCampos(){
			this.campos = ''
      this.banderaSave = false
			document.getElementById('alert').style.display = 'none'
			if (this.plazoContruccion && this.periodo) {
				this.campos = Math.floor(this.plazoContruccion / this.periodo)				
			}								
		},
		removeInputs(){
			document.getElementById("inputFormRow").remove();
		},
    calculaPorcentaje(){
			this.banderaSave = false;
			document.getElementById('alert').style.display = 'none'			
			var calcular = 0;

			for (let p = 1; p <= this.campos; p++) {				
				calcular += parseInt(this.camposID.name[p])				
			}
			
			if (calcular != 100) {
				document.getElementById('alert').style.display = 'block'
			}else{
				this.banderaSave = true;			
			}

		},
		actualizaCampos(){			
			for (let p = 1; p <= 18; p++) {
				this.camposID.name[p] = 0				
			}
		},
    SoloNumero: function(evt){
			evt = evt ? evt : window.event
			var charCode = evt.which ? evt.which : evt.keyCode
			if (charCode > 31 && (charCode < 48 || charCode > 57)) {
				evt.preventDefault()
			} else {
				return true
			}
		},
    dameMinistracion(){
			let self = this
			let objRequestMinistracion = {
				strApiKey: this.key,
				strMetodo: 'dameMinistracion',
				objCaso : {
					idCaso : this.id
				}
			}
			this.$axios.post('/',objRequestMinistracion, { headers: { 'Content-Type':'application/x-www-form-urlencoded; charset = UTF-8' } } )
			.then((response) => {

				if (response.data.intEstatus == 0) {
					let objMinistracion = response.data.objContenido
					this.usoMinistraciones = 'Si'
					this.plazoContruccion = objMinistracion.Plazo                    
					
					if (this.plazoContruccion < 5) {
						this. periodoMinistraciones =  [
							{label: '-- Seleccione --', value: ''},
							{label : 'Mensual', value: 1},
							{label : 'Bimestral', value: 2},
							{label : 'Trimestral', value: 3},
						]
					}else if(this.plazoContruccion < 7){
						this.periodoMinistraciones= [
							{label: '-- Seleccione --', value: ''},
							{label : 'Mensual', value: 1},
							{label : 'Bimestral', value: 2},
							{label : 'Trimestral', value: 3},
							{label : 'Cuatrimestral', value: 4},					
						]
					}else if(this.plazoContruccion < 13){
						this.periodoMinistraciones = [
							{label: '-- Seleccione --', value: ''},
							{label : 'Mensual', value: 1},
							{label : 'Bimestral', value: 2},
							{label : 'Trimestral', value: 3},
							{label : 'Cuatrimestral', value: 4},
							{label : 'Semestral', value: 6},
							
						]
					}else{
						this.periodoMinistraciones = [
							{label: '-- Seleccione --', value: ''},
							{label : 'Mensual', value: 1},
							{label : 'Bimestral', value: 2},
							{label : 'Trimestral', value: 3},
							{label : 'Cuatrimestral', value: 4},
							{label : 'Semestral', value: 6},
							{label : 'Anual', value: 12}
						]
					}
					
					this.periodo = objMinistracion.Periodo
					this.campos = Math.floor(this.plazoContruccion / this.periodo)					

					let arrayPorcentajes = objMinistracion.Porcentajes.split(',')
          var calcular = 0;

					/*for (let index = 0; index < arrayPorcentajes.length; index++) {
						console.log("["+index+"]"+arrayPorcentajes[index])						
					}*/

					for (let i = 1; i <= this.campos; i++) {
						this.camposID.name[i] = arrayPorcentajes[i-1];						
            calcular += parseInt(this.camposID.name[i])												
					}

          
					// valida el porcentaje en cada uno de los campos
										
					if (calcular != 100) {
						document.getElementById('alert').style.display = 'block'
					}else{
						this.banderaSave = true;						
					}

				}else{
					console.log("Sin ministraciones")
				}

			}).catch(function (error){
					self.$vs.notify({
					title: 'Ocurrio un error en DameMinistracion',
					text: error,
					color: 'danger',
					position: 'top-right',
				})
			})
			
		},

		// aportacion voluntaria
    getPrepagoList() {
      let self = this
      let objRequestPrepagoList = {
        strApiKey: this.key,
        strMetodo: 'ListaAportacionPrepagos',
      };
      
      
      this.$axios
        .post(
              '/', 
              objRequestPrepagoList, 
              { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } }
        )
        .then((response) => {
          if (response.data.intEstatus == 0) {
            this.prepagoList = response.data.objContenido
          } else {
            this.$vs.notify({
              title: 'Ocurrio un error en ListaAportacionPrepagos',
              text: response.data.strError,
              color: 'danger',
              position: 'top-right',
            })
          }
        })
        .catch(function (error) {
          self.$vs.notify({
            title: 'Ocurrio un error de sistema',
            text: error,
            color: 'danger',
            position: 'top-right',
          })
        })
    },
    getPlazoAportacionList() {
      let self = this
      let objRequestPlazoAportacionList = {
        strApiKey: this.key,
        strMetodo: 'ListaAportacionPlazos',
      };
      
      this.$axios
        .post(
              '/', 
              objRequestPlazoAportacionList, 
              { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } }
        )
        .then((response) => {
          if (response.data.intEstatus == 0) {
            this.plazosList = response.data.objContenido
          } else {
            this.$vs.notify({
              title: 'Ocurrio un error en ListaAportacionPlazos',
              text: response.data.strError,
              color: 'danger',
              position: 'top-right',
						})
          }
        })
        .catch(function (error) {
          self.$vs.notify({
            title: 'Ocurrio un error de sistema',
            text: error,
            color: 'danger',
            position: 'top-right',
          })
        })
    },
    checkApoVol() {
      this.aportacionVoluntaria = this.aportacionVoluntaria.replace(/,/g,"")
      const regex = /^(\d{1,8})(\.\d{0,2})?$/  
      const matches = regex.test(this.aportacionVoluntaria);
      if (matches === true) {
        this.aportacionVoluntaria = this.aportacionVoluntaria
      } else {
        this.aportacionVoluntaria = this.aportacionVoluntaria.replace(/.$/, '')
      }
    },
    setFormatApoVol() {
      if (this.aportacionVoluntaria == '') { 
        this.aportacionVoluntaria = 0 
      }
      this.aportacionVoluntaria = parseFloat(this.aportacionVoluntaria).toFixed(2)
      const exp = /(\d)(?=(\d{3})+(?!\d))/g
      const rep = '$1,'
      this.aportacionVoluntaria = this.aportacionVoluntaria.toString().replace(exp, rep)
    },
    setPlazoAportacion() {
      // al cambiar el plazo se reinicia el numero de aportaciones
      this.numeroAportacion = '';
      this.helperCantidadAportacion = '';
    },
		// aportacion voluntaria

		calculaFactorSantander(aforo, plazo, credito) {

			let aforoMultiplo = Math.ceil(aforo / 5) * 5; // obtiene multiplo de 5 mas cercano, si es multiplo exacto queda igual
			let factor;
			let montoAdicional = 0;
			
			if (aforoMultiplo >= 5 && aforoMultiplo <= 50) {
				factor = this.factorPorcentualSantander.aforoTipoA.find((item) => item.plazo == plazo);
			} else if (aforoMultiplo >= 55 && aforoMultiplo <= 70) {
				factor = this.factorPorcentualSantander.aforoTipoB.find((item) => item.plazo == plazo);
			}else if (aforoMultiplo == 75) {
				factor = this.factorPorcentualSantander.aforoTipoC.find((item) => item.plazo == plazo);
			}else if (aforoMultiplo == 80) {  
				factor = this.factorPorcentualSantander.aforoTipoD.find((item) => item.plazo == plazo);
			}else if (aforoMultiplo == 85) {
				factor = this.factorPorcentualSantander.aforoTipoE.find((item) => item.plazo == plazo);
			}else if (aforoMultiplo >= 90 && aforoMultiplo <= 100 ) {
				factor = this.factorPorcentualSantander.aforoTipoF.find((item) => item.plazo == plazo);
			}

			// verifica si encontro algun plazo valido
			if (!factor) {
				montoAdicional = 0;
				// console.log( 'No se encontro plazo', montoAdicional );
				return montoAdicional;
			}

			montoAdicional = ((factor.porcentaje / 100) * credito).toFixed(2);
			montoAdicional = Number(montoAdicional);

			// console.log( 'MontoData: ', factor.porcentaje, credito, plazo,  montoAdicional );
			return montoAdicional;
		},

    seleccionarTodos(){
      this.caso.bancos=this.bancos
    }, 
    limpiarBancos(){
      this.caso.bancos=[]
    },
    isNumber: function(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    irImprimir(){
       localStorage.setItem('vistaNuevaCotizacion', true)
      this.$router.push('/imprimir/'+this.id);
    },
    RegistraCasoKrediDev(destinoId,ValorInmuebleKredi,EngancheKredi,PorcentajeEngancheKredi,PlazosKredi,FinanciamientoKredi,ComisionAperturaKredi,EstadoKredi,GastosNotarialesKredi){
      let respuestaKredi = ''

      
      console.log("Ans Jorge "+ respuestaKredi)

      let objRequestRegistraSolicitante = {
				strApiKey: this.key,
				strMetodo: 'metodoKredi',
      }

      this.$axios
				.post('/', objRequestRegistraSolicitante, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
				.then((response) => {

          respuestaKredi =JSON.parse(response.data.objRespuesta)
          console.log(respuestaKredi)

          let itemsKredi = {}
						let items = []
						let cantidadCotiza = items.length
						itemsKredi.Monto= parseFloat(respuestaKredi.data.attributes.answers.monto_del_credito_solicitado)
						itemsKredi.Cat = parseFloat(respuestaKredi.data.attributes.cat *100)
						itemsKredi.Mensualidad = parseFloat(respuestaKredi.data.attributes.monthly_payment)
						itemsKredi.Tasa = parseFloat(respuestaKredi.data.attributes.answers.tasa_de_interes * 100)
						itemsKredi.IngresoMinimo = parseFloat(respuestaKredi.data.attributes.minimum_income_required) 
			
						itemsKredi.GastosIniciales = parseFloat(respuestaKredi.data.attributes.answers.valor_avaluo + respuestaKredi.data.attributes.answers.gastos_notariales_aproximados + respuestaKredi.data.attributes.answers.comision_por_apertura) 
						itemsKredi.Avaluo= parseFloat(respuestaKredi.data.attributes.answers.valor_avaluo)
						itemsKredi.ComisionApertura= parseFloat(respuestaKredi.data.attributes.answers.comision_por_apertura)
						itemsKredi.GastosNotariales= parseFloat(respuestaKredi.data.attributes.answers.gastos_notariales_aproximados)
						
						itemsKredi.Aforo = respuestaKredi.data.attributes.answers.aforo * 100
						itemsKredi.Plazo = respuestaKredi.data.attributes.answers.plazo_en_meses
						itemsKredi.PagoTotal = 0
						itemsKredi.MensualidadPromedio = parseFloat(respuestaKredi.data.attributes.monthly_payment)
						itemsKredi.MensualidadInicial = parseFloat(respuestaKredi.data.attributes.monthly_payment)
						itemsKredi.BancoId = 33
						itemsKredi.Banco = 'KREDI'
						if(respuestaKredi.data.attributes.product_id==1){
							itemsKredi.Producto = 'KREDI ADQUISICIÓN DE VIVIENDA'
              itemsKredi.Enganche = parseFloat(respuestaKredi.data.attributes.hitch) 
						}else if(respuestaKredi.data.attributes.product_id==2){
							itemsKredi.Producto = 'KREDI MEJORA DE HIPOTECA ORIGEN VIVIENDA'
              itemsKredi.Enganche = 0
              itemsKredi.MejoraMensualidad=0
              itemsKredi.AhorroTotal =0
              itemsKredi.Monto= this.caso.montoSolicitado
              itemsKredi.Aforo=this.caso.montoSolicitado.replace(/,/g, '') / this.caso.valorInmueble.replace(/,/g, '') * 100

						}
						
						itemsKredi.Esquema = 2
						itemsKredi.Logo = 'kredi.png'
						//itemsKredi.PdfAmortizacion = respuestaIon.data.amortization_table.file
						itemsKredi.JsonAmortizacion = respuestaKredi.data.attributes.last_amortization_table
						itemsKredi.IdIon = respuestaKredi.data.id
						this.items.push(itemsKredi)

            console.log(itemsKredi)
          

        })
				.catch(function (error) {
					self.$vs.notify({
						title: 'Ocurrio un errros en api KREDI',
						text: error,
						color: 'danger',
						position: 'top-right',
					})
				})


    },
    RegistraCasoKredi(destinoId,ValorInmuebleKredi,EngancheKredi,PorcentajeEngancheKredi,PlazosKredi,FinanciamientoKredi,ComisionAperturaKredi,EstadoKredi,GastosNotarialesKredi){			
			let self = this
			let producto = ''
			let respuestaKredi = ''


			if(destinoId==1){
				producto=1
			}else if(destinoId==19){
				producto=2
			}

			var product_application = {
				product_id: producto,
				answers: {
					"fecha": "02/06/2023",
					"valor_del_inmueble": ValorInmuebleKredi,
					"enganche": EngancheKredi,
					"porcentaje_de_enganche": PorcentajeEngancheKredi,
					"plazo_en_anos": PlazosKredi,
					"financiamiento_de_comision_por_apertura": FinanciamientoKredi,
					"porcentaje_comision_por_apertura": ComisionAperturaKredi,
				
					"estado_en_donde_se_encuentra_la_propiedad": EstadoKredi,
					"porcentaje_gastos_notariales": "null"

				},
				"async": false,
				"customer_id": "",
				"external_id": "",
				"dry_run": true
			}
			this.$axios
				.post('https://cotizador.socasesores.com/ApiION/MethodoKredi.php', product_application, { headers: { 'Content-Type': 'application/json; charset=UTF-8' } })
				.then((response) => {

	

					respuestaKredi = JSON.parse(response.data.objRespuesta)
		

	
						let itemsKredi = {}
						let items = []
						let cantidadCotiza = items.length
						
						itemsKredi.Cat = parseFloat(respuestaKredi.data.attributes.cat *100)
						itemsKredi.Mensualidad = parseFloat(respuestaKredi.data.attributes.monthly_payment)
						itemsKredi.Tasa = parseFloat(respuestaKredi.data.attributes.answers.tasa_de_interes * 100)
						itemsKredi.IngresoMinimo = parseFloat(respuestaKredi.data.attributes.minimum_income_required) 
			
						itemsKredi.GastosIniciales = parseFloat(respuestaKredi.data.attributes.answers.valor_avaluo + respuestaKredi.data.attributes.answers.gastos_notariales_aproximados + respuestaKredi.data.attributes.answers.comision_por_apertura) 
						itemsKredi.Avaluo= parseFloat(respuestaKredi.data.attributes.answers.valor_avaluo)
						itemsKredi.ComisionApertura= parseFloat(respuestaKredi.data.attributes.answers.comision_por_apertura)
						itemsKredi.GastosNotariales= parseFloat(respuestaKredi.data.attributes.answers.gastos_notariales_aproximados)
						
						
            
						itemsKredi.Plazo = respuestaKredi.data.attributes.answers.plazo_en_meses
						itemsKredi.PagoTotal = 0
						itemsKredi.MensualidadPromedio = parseFloat(respuestaKredi.data.attributes.monthly_payment)
						itemsKredi.MensualidadInicial = parseFloat(respuestaKredi.data.attributes.monthly_payment)
						itemsKredi.BancoId = 33
						itemsKredi.Banco = 'KREDI'
						if(respuestaKredi.data.attributes.product_id==1){
							itemsKredi.Producto = 'KREDI ADQUISICIÓN DE VIVIENDA'
              itemsKredi.Enganche = parseFloat(respuestaKredi.data.attributes.hitch) 
              itemsKredi.Monto= parseFloat(respuestaKredi.data.attributes.answers.monto_del_credito_solicitado)
              itemsKredi.Aforo = respuestaKredi.data.attributes.answers.aforo * 100
              
						}else if(respuestaKredi.data.attributes.product_id==2){
							itemsKredi.Producto = 'KREDI MEJORA DE HIPOTECA ORIGEN VIVIENDA'
              itemsKredi.Enganche = 0
              itemsKredi.MejoraMensualidad=0
              itemsKredi.AhorroTotal =0
              itemsKredi.Monto= parseFloat(this.caso.montoSolicitado.replace(/,/g, ''))
              itemsKredi.Aforo = (parseFloat(this.caso.montoSolicitado.replace(/,/g, '')) / parseFloat(this.caso.valorInmueble.replace(/,/g, ''))) * 100               

						}
						
						itemsKredi.Esquema = 2
						itemsKredi.Logo = 'kredi.png'
						//itemsKredi.PdfAmortizacion = respuestaIon.data.amortization_table.file
						itemsKredi.JsonAmortizacion = respuestaKredi.data.attributes.last_amortization_table
						itemsKredi.IdIon = respuestaKredi.data.id
						this.items.push(itemsKredi)   
            
            console.log("REGISTRACASOKREDI")
            console.log(this.items)
            console.log(respuestaKredi)
				})
				.catch(function (error) {
					self.$vs.notify({
						title: 'Ocurrio un errros en api KREDI',
						text: error,
						color: 'danger',
						position: 'top-right',
					})
				})
		},
    pruebaIon(plazo, destinoId){
     /* product i.	Adquisición de vivienda = REAL_STATE
      ii.	Liquidación = LIQUIDITY
      iii.	Oficinas y locales = OFFICES_AND_STORES
      iv.	Adquisición de terreno = LAND
      */
     let self= this
     let destino=''
     let GastosInicialCalculado= ''
     let NombreProducto=''
     let amount =''
     let respuestaData=''
     let calculoAforo=''

			calculoAforo = (this.caso.montoSolicitado.replace(/,/g, '') * 100) / this.caso.valorInmueble.replace(/,/g, '')

			switch (destinoId) {
				case 1:
					destino = 'REAL_ESTATE'
					GastosInicialCalculado = this.caso.valorInmueble.replace(/,/g, '') * 0.08
					NombreProducto = 'ION Adquisición Plus'
					break
				case 3:
					destino = 'LIQUIDITY'
					GastosInicialCalculado = this.caso.valorInmueble.replace(/,/g, '') * 0.03
					NombreProducto = 'ION Liquidez Plus'
					break
				case 5:
					destino = 'LAND'
					GastosInicialCalculado = this.caso.valorInmueble.replace(/,/g, '') * 0.08
					NombreProducto = 'ION Adquisición de Terreno Plus'
					break
				case 37:
					destino = 'OFFICES_AND_STORES'
					GastosInicialCalculado = this.caso.valorInmueble.replace(/,/g, '') * 0.08
					NombreProducto = 'ION Oficinas y Locales Plus'
					break
			}
			var objGeneraCodigo = {
				person_type: 'NATURAL', //valor fijo
				product: destino, //producto
				required_amount: { amount: this.caso.montoSolicitado.replace(/,/g, ''), currency: 'MXN' }, // cantidad requerida
				monthly_income: { amount: 50000000, currency: 'MXN' }, //ingreso mensual valor fijo
				real_estate_value: { amount: this.caso.valorInmueble.replace(/,/g, ''), currency: 'MXN' }, //valor inmobiliario
				term: parseInt(plazo),
			}
			this.$axios
				.post('https://cotizador.socasesores.com/ApiION/MethodoIon.php', objGeneraCodigo, { headers: { 'Content-Type': 'application/json; charset=UTF-8' } })
				.then((response) => {
					respuestaData = JSON.parse(response.data.objRespuesta)

					/* if(respuestaData.notification.level=='ERROR'){

                 this.mostrarOpciones=true
                this.mostrarDatosCotizacion=false
                this.$vs.loading.close('#cotizacion > .con-vs-loading')
                if(localStorage.getItem('regreso')){
                  localStorage.removeItem('vistaNuevaCotizacion')
                  localStorage.removeItem('regreso')
                }

              }
              else*/
					if (respuestaData.message == 'Internal server error') {
						this.mostrarOpciones = true
						this.mostrarDatosCotizacion = false
						this.$vs.loading.close('#cotizacion > .con-vs-loading')
						if (localStorage.getItem('regreso')) {
							localStorage.removeItem('vistaNuevaCotizacion')
							localStorage.removeItem('regreso')
						}

						self.$vs.notify({
							title: 'Internal server error ION',
							text: error,
							color: 'danger',
							position: 'top-right',
						})
					} else {
						var respuestaIon = JSON.parse(response.data.objRespuesta)
						let itemsIon = {}
						let items = []
						let cantidadCotiza = items.length
						itemsIon.Cat = parseFloat(respuestaIon.data.cat)
						itemsIon.Mensualidad = parseFloat(respuestaIon.data.monthly_payment.amount)
						itemsIon.Tasa = parseFloat(respuestaIon.data.interest_rate)
						itemsIon.IngresoMinimo = parseFloat(respuestaIon.data.minimum_income) // parseFloat(respuestaIon.data.monthly_payment.amount)/33
						itemsIon.Enganche = parseFloat(this.caso.valorInmueble.replace(/,/g, '') - this.caso.montoSolicitado.replace(/,/g, ''))
						itemsIon.GastosIniciales = parseFloat(respuestaIon.data.initial_expenses)
						itemsIon.Aforo = calculoAforo
						itemsIon.Plazo = respuestaIon.data.term * 12
						itemsIon.PagoTotal = parseFloat(respuestaIon.data.total_payment)
						itemsIon.Monto = parseFloat(this.caso.montoSolicitado.replace(/,/g, ''))
						itemsIon.MensualidadPromedio = parseFloat(respuestaIon.data.monthly_payment.amount)
						itemsIon.MensualidadInicial = parseFloat(respuestaIon.data.monthly_payment.amount)
						itemsIon.BancoId = 21
						itemsIon.Banco = 'ION'
						itemsIon.Producto = NombreProducto
						itemsIon.Esquema = 2
						itemsIon.Logo = 'LogoIon_130622.png'
						itemsIon.PdfAmortizacion = respuestaIon.data.amortization_table.file
						itemsIon.JsonAmortizacion = respuestaIon.data.amortization_table.raw_data
						itemsIon.IdIon = respuestaIon.data.id
						this.items.push(itemsIon)
						//itemsIon.push(parseInt(this.caso.esquemas[i].value))

						this.items.sort((a, b) => (a.MensualidadPromedio > b.MensualidadPromedio ? 1 : b.MensualidadPromedio > a.MensualidadPromedio ? -1 : 0))
						this.mostrarOpciones = true
						this.mostrarDatosCotizacion = false
						this.$vs.loading.close('#cotizacion > .con-vs-loading')
						if (localStorage.getItem('regreso')) {
							localStorage.removeItem('vistaNuevaCotizacion')
							localStorage.removeItem('regreso')
						}
					}

					this.$vs.loading.close('#cotizacion > .con-vs-loading')
				})
				.catch(function (error) {
					self.$vs.notify({
						title: 'Ocurrio un errros en apiIon',
						text: error,
						color: 'danger',
						position: 'top-right',
					})
				})
		},
		registrarSolicitante() {
			let self = this
			/*this.$vs.loading({
        container: '#cotizacion',
        scale: 0.6
      })*/
			let objRequestRegistraSolicitante = {
				strApiKey: this.key,
				strMetodo: 'RegistraSolicitante',
				objSolicitante: {
					EMail: this.email,
					Nombres: this.nombre,
					SegundoNombre: this.segundoNombre,
					ApellidoPaterno: this.apellidoPaterno,
					ApellidoMaterno: this.apellidoMaterno,
					TelefonoCelular: this.telefono,
					FechaNacimiento: '1992-01-01',
          StateData: 0,
					Curp: this.curp,
          AccreditedIdV3: this.IdLiga
				},
        objSolicitanteExtra: {
          CheckReplication: true,
        }
			}
			this.$axios
				.post('/', objRequestRegistraSolicitante, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
				.then((response) => {
					if (response.data.intEstatus == 0) {
						this.dameInformacion()
					} else {
						this.$vs.notify({
							title: 'Ocurrio un error en RegistraSolicitante',
							text: response.data.strError,
							color: 'danger',
							position: 'top-right',
						})
					}
				})
				.catch(function (error) {
					self.$vs.notify({
						title: 'Ocurrio un error de sistema',
						text: error,
						color: 'danger',
						position: 'top-right',
					})
				})
		},
		ValidaSolicitante() {
			let self = this

			if (this.curp != '') {


        if(this.$store.state.AppActiveUser.OrganizacionTipo!=2){
						this.GeneraLiga()
				}else{
          document.getElementById('btnGuardar').style.display = 'none'
            
			   	this.registrarSolicitante()

        }   

			} else {
				document.getElementById('btnGuardar').style.display = 'none'
        this.PreRegistroV3()
				this.registrarSolicitante()
			}
		},
    dameTokenV3(){
      let objRequestDameTokenPv3 = {
      strApiKey: this.key,
      strMetodo: 'DameTokenPv3'
      }
      this.$axios.post('/', objRequestDameTokenPv3, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
        .then((response) => {
          if (response) {
            this.token = response.data.access_token;
            this.GeneraLiga();
          }
          else{
            this.$vs.notify({
              title: 'Ocurrio un error en Obtener token de Pv3',
              text: response.data.strError,
              color: 'danger',
              position: 'top-right',
            })
          }
        })
        .catch(function (error) {
          self.$vs.notify({
            title: 'Ocurrio un error de sistema',
            text: error,
            color: 'danger',
            position: 'top-right',
          })
        })
    },
		regresar() {
			this.mostrarDatosCotizacion = false
			this.mostrarInfoSolicitante = false
			this.mostrarOpciones = true
		},
		regresarAcotizacion() {
			this.mostrarDatosCotizacion = true
			this.mostrarInfoSolicitante = false
			this.mostrarOpciones = false
		},
		mostrarFormSolicitante() {
			this.mostrarDatosCotizacion = false
			this.mostrarInfoSolicitante = true
			this.mostrarOpciones = false
		},
		getEstados() {
			let self = this
			var objRequestListaEstados = {
				strApiKey: this.key,
				strMetodo: 'ListaEstados',
			}
			this.$axios
				.post('/', objRequestListaEstados, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
				.then((response) => {
					if (response.data.intEstatus == 0) {
						this.estados = response.data.objContenido
					} else {
						this.$vs.notify({
							title: 'Ocurrio un error en ListaEstados',
							text: response.data.strError,
							color: 'danger',
							position: 'top-right',
						})
					}
				})
				.catch(function (error) {
					self.$vs.notify({
						title: 'Ocurrio un error de sistema',
						text: error,
						color: 'danger',
						position: 'top-right',
					})
				})
		},
		registraAdicionales() {
			let self = this
			var objRequestAdicionales = {
				strApiKey: this.key,
				strMetodo: 'RegistraAdicionalesSolicitud',
				objCaso: {
					Id: this.id,
					InmuebleGarantia: {
						//InmuebleNuevo:this.caso.inmuebleNuevo,
						Estado: this.caso.estado,
					},
				},
			}
			this.$axios
				.post('/', objRequestAdicionales, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
				.then((response) => {
					if (response.data.intEstatus == 0) {
						this.$vs.notify({
							title: `Guardardo exitosamente`,
							position: 'top-right',
						})
						//this.dameCaso()
					} else {
						this.$vs.notify({
							title: 'Ocurrio un error en RegistraAdicionalesSolicitud',
							text: response.data.strError,
							color: 'danger',
							position: 'top-right',
						})
					}
				})
				.catch(function (error) {
					self.$vs.notify({
						title: 'Ocurrio un error de sistema',
						text: error,
						color: 'danger',
						position: 'top-right',
					})
				})
		},

		mostrarProgramas() {
			this.programas = []
      //this.caso.programa = this.Programa
      this.usoMinistraciones = 'No'

			// 1: Sin Programa || 6: Apoyo Infonavit || 4: Cofinamiento Infonavit || 9: Fovisste || 10: Fovisste para Todos

			if (this.caso.destino == 1 || this.caso.destino == 5 || this.caso.destino == 2 || this.caso.destino == 15) {
				this.programas = this.todosProgramas
			} 
			else if (this.caso.destino == 20) {

				this.programas = []
        this.caso.programa = 1
				var auxProgramas = [1, 6]
				var auxProgramaExiste = this.todosProgramas
				for (var j = 0; j < auxProgramaExiste.length; j++) {
					if (auxProgramas.includes(auxProgramaExiste[j]['Id'])) {
						this.programas.push(auxProgramaExiste[j])
					}
				}

			} 
			else if ( this.caso.destino == 19 ) { // Mejora tu Hipoteca Origen Vivienda

				this.programas = []
				var auxProgramas = [1, 6, 4, 9]
				var auxProgramaExiste = this.todosProgramas
				for (var j = 0; j < auxProgramaExiste.length; j++) {
					if (auxProgramas.includes(auxProgramaExiste[j]['Id'])) {
						this.programas.push(auxProgramaExiste[j])
					}
				}

				const orderPrograms = auxProgramas.map(x => {
					const exist = this.programas.find(programa => x === programa.Id)

					return exist && exist
				})

				this.programas = orderPrograms

			}
			else {
				
				this.programas = []
        this.caso.programa = 1
				var auxProgramas = [1, 9, 4]
				var auxProgramaExiste = this.todosProgramas
				for (var j = 0; j < auxProgramaExiste.length; j++) {
					if (auxProgramas.includes(auxProgramaExiste[j]['Id'])) {
						this.programas.push(auxProgramaExiste[j])
					}
				}

			}

      if (this.caso.PrecalificacionTipo == 1) {
        var Destino = this.caso.destino
        this.cambiaBancos(Destino, 'destino')
  
        this.getDataDestino()
      }
		},

    validateFavoritos () {

			const countFavoritos = this.caso.favoritos.length
			this.isLimitFavoritos = countFavoritos >= 5 ? true : false

		},

		cambia() {
			this.otro = this.otro + 1
		},
		factorySettings() {
			this.items = []
		},
		numberFormat(number) {
			return number
				.toFixed(2)
				.toString()
				.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
		},
		update(e) {  
      // console.log( 'UPDATE NUEVA COTIZACION, destino: ', e.destino );

      this.porcentajeActivaCotiza = true; // si deja inactivo auto cambiando a hipotecario
      
      console.log("impresion")
      console.log(e)

			if (e.otro) {
				this.otro = 0
			}
			this.caso.plazos = []
			this.caso.esquemas = []
			for (var i = 0; i < e.plazosACotizar.length; i++) {
				if (typeof e.plazosACotizar[i] == 'object') {
					this.caso.plazos.push(e.plazosACotizar[i].value)
				} else {
					this.caso.plazos.push(e.plazosACotizar[i].substring(0, 2))
				}
			}
			for (var i = 0; i < e.esquemasACotizar.length; i++) {
				this.caso.esquemas.push(parseInt(e.esquemasACotizar[i].value))
			}
			if (e.destino == 19 || e.destino == 25 || e.destino == 22 || e.destino == 33 || e.destino == 34){
				this.caso.valorInmueble = e.valor
				this.caso.montoSolicitado = e.credito
				this.caso.pagoMensualActual = e.mensualidad
				this.caso.plazoRestante = e.plazoRestante
			} else if (e.destino == 11 && this.caso.destino == 19) {
        this.caso.valorInmueble = e.valor
				//this.caso.montoSolicitado = '0'
        //this.caso.montoSolicitado = e.credito
        //this.caso.pagoMensualActual = e.mensualidad
        e.mensualidad ? this.caso.pagoMensualActual = e.mensualidad : ''
				//this.caso.plazoRestante = e.plazoRestante
        e.plazoRestante ? this.caso.plazoRestante = e.plazoRestante : ''
				this.caso.ssv = e.ssv
				this.caso.creditoInf = e.creditoInf
				if (this.caso.programa == 9 || this.caso.programa == 4) {
					this.caso.montoCredito = e.montoCredito
				}

      } else if (e.destino == 11) {
				this.caso.valorInmueble = e.valor
				this.caso.montoSolicitado = '0'
				this.caso.ssv = e.ssv
				this.caso.creditoInf = e.creditoInf
				if (this.caso.programa == 9 || this.caso.programa == 4) {
					this.caso.montoCredito = e.montoCredito
				}
			} else if (this.autosDestinos.includes(e.destino)) {
				this.caso.aniosACotizar = e.aniosACotizar;
				this.caso.valorInmueble = e.valor;
				this.caso.montoSolicitado = e.credito;
				this.caso.plazos = e.plazosACotizar;
				this.caso.destino = e.destino;

        if (e.activaCotizaMenor || e.activaCotizaMayor) { // si uno de los 2 es invalido
          this.porcentajeActivaCotiza = false;

          if (this.caso.destino == 45) { // si es auto y destino 45 donde no existe monto enganche
            this.porcentajeActivaCotiza = true; // activo boton cotiza
          }

        } else {
          this.porcentajeActivaCotiza = true;
        }
			} else {
				// this.destino=e.destino
				this.caso.valorInmueble = e.valor
				this.caso.montoSolicitado = e.credito
			}
		},
		cambiaBancos(e, origen) {

      if (origen == 'destino') {
        this.bancos = []
        this.caso.destino = e;
      }else{
		
      	this.bancos = []
  			this.caso.plazos = []
  			this.caso.esquemas = []
       
  			for (var i = 0; i < e.plazosACotizar.length; i++) {
  				if (typeof e.plazosACotizar[i] == 'object') {

  					this.caso.plazos.push(parseInt(e.plazosACotizar[i].value))
            
  				} else {
  					this.caso.plazos.push(e.plazosACotizar[i].substring(0, 2))
          
  				}
  			}
  			for (var i = 0; i < e.esquemasACotizar.length; i++) {
  				this.caso.esquemas.push(parseInt(e.esquemasACotizar[i].value))
  			}
      }

			let self = this
			let objRequestDamePlazos = {
				strApiKey: this.key,
				strMetodo: 'ListaBancosPlazos',
				objCaso: {
          Destino: this.caso.destino,
					Esquema: this.caso.esquemas,
					Plazos: this.caso.plazos,
				},
			}
			this.$axios
				.post('/', objRequestDamePlazos, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
				.then((response) => {
					if (response.data.intEstatus == 0) {
            
        ////  if(this.caso.plazos.length==1){
            /*  if(this.caso.plazos[0]=='25'){
            
               this.caso.bancos=[]

                this.caso.bancos.push({ label: 'BANORTE', value: 4 })
                 this.caso.bancos.push({ label: 'HSBC', value: 6 })
               
              	this.bancos.push({ label: 'BANORTE', value: 4 })
                this.bancos.push({ label: 'HSBC', value: 6 })
                
            }else{
              let bancos = response.data.objContenido
                for (var i = 0; i < bancos.length; i++) {
                  this.bancos.push({ label: bancos[i].Nombre, value: bancos[i].Id })
                }
                  this.bancos.push({ label: 'KREDI', value: 33 })
                 //this.caso.bancos = this.bancosEjecutivo //cambio bancos
                 this.caso.bancos = this.bancos                  
           }*/

       ///    }else{
             
                let bancos = response.data.objContenido
                for (var i = 0; i < bancos.length; i++) {
                  this.bancos.push({ label: bancos[i].Nombre, value: bancos[i].Id })
                }
                  this.bancos.push({ label: 'KREDI', value: 33 })
              	 //this.caso.bancos = this.bancosEjecutivo //cambio bancos
                 this.caso.bancos = this.bancos 
                 
                 
                /**
                * Solo se mostrara el banco banorte si el plazo es 21,22,24 sin combinar con otros plazos
                */
                let checkPlazos = [];
                checkPlazos = this.caso.plazos;
                if (checkPlazos.length > 0 && checkPlazos.length < 4) { // solo puede tener 3 plazos max
                  let findPlazo = this.caso.plazos.find(item => (item != 21 && item != 22 && item != 24));

                  if (!findPlazo) { // si no se encontro otro plazo diferente a 21,22,24
                    this.caso.bancos = [];
                    this.bancos = [];
                    this.caso.bancos.push({ label: 'BANORTE', value: 4 });
                    this.bancos.push({ label: 'BANORTE', value: 4 });
                  }
                }

        ///   }           

					} else if(response.data.intEstatus == 1){
            this.caso.bancos = [];
          }else {
						this.$vs.notify({
							title: 'Ocurrio un error en ListaFinancieras',
							text: response.data.strError,
							color: 'danger',
							position: 'top-right',
						})
					}
				})
				.catch(function (error) {
					self.$vs.notify({
						title: 'Ocurrio un error en DamePlazos',
						text: error,
						color: 'danger',
						position: 'top-right',
					})
				})
		},
		callMethodInParentComponent() {
			this.$emit('callMethodInParent')
			// alert("hola");
		},

    async getListProductsMensualidad ({ listProducts }) {

			try {
	
				const objRequestListProducts = {
					strApiKey: this.key,
					strMetodo: 'ListProductsMensualidad',
					objList: {
						listProducts
					}
				}

				const resp = await this.$axios.post('/', objRequestListProducts, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
				const { intEstatus, data } = resp
				const { objContenido } = data

				if ( !objContenido ) {
					this.$vs.notify({
						title: 'Ocurrio un error en ListProductsMensualidad',
						text: data.strError,
						color: 'danger',
						position: 'top-right',
					})

					return []
				}

				return objContenido
				
			} catch (error) {
				this.$vs.notify({
					title: 'Ocurrio un error de sistema',
					text: error,
					color: 'danger',
					position: 'top-right',
				})
			}

		},

		async cotiza(id, destinoId) {

      try {
        
        this.$vs.loading({
          container: '#cotizacion',
          scale: 0.6,
        })

        const objRequestCotizaCaso = {
          strApiKey: this.key,
          strMetodo: 'Cotiza',
          objCaso: {
          Id: id,
          //GastoNotarial: this.caso.gastosNotarial
          }
        }

        const resp = await this.$axios.post('/',objRequestCotizaCaso,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
				const { intEstatus, data } = resp
        
				if ( intEstatus ) {
					this.$vs.notify({
						title: 'Ocurrio un error en Cotiza',
						text: data.strError,
						color: 'danger',
						position: 'top-right',
					})
				}

        //Mejora ion
        let bancos = []
        let esquemas = []

        for (var i = 0; i < this.caso.bancos.length; i++) {
          if (this.caso.bancos[i].value) {
            bancos.push(parseInt(this.caso.bancos[i].value))
          } else {
            bancos.push(parseInt(this.caso.bancos[i]))
          }
        }

        for (var i = 0; i < this.caso.esquemas.length; i++) {
          if (this.caso.esquemas[i].value) {
            esquemas.push(parseInt(this.caso.esquemas[i].value))
          } else {
            esquemas.push(parseInt(this.caso.esquemas[i]))
          }
        }

				// Consultar segunda mensualidad para todos los productos
				const listProducts = data.objContenido
				const listItems = listProducts.length > 0 ? await this.getListProductsMensualidad({ listProducts }) : listProducts

				// console.log('listItems :>> ', listItems)
				this.items = listItems
        //this.items = data.objContenido

        ////////////////////////////*************
        for (var j = 0; j < this.caso.plazos.length; j++) {
          if ((this.caso.programa == 1 && esquemas.includes(2) && bancos.includes(21) && (destinoId == 1 || destinoId == 3) && (this.caso.plazos[j] == 5 || this.caso.plazos[j] == 6 || this.caso.plazos[j] == 7 || this.caso.plazos[j] == 8 || this.caso.plazos[j] == 9 || this.caso.plazos[j] == 10 || this.caso.plazos[j] == 11 || this.caso.plazos[j] == 12 || this.caso.plazos[j] == 13 || this.caso.plazos[j] == 14 || this.caso.plazos[j] == 15 || this.caso.plazos[j] == 16 || this.caso.plazos[j] == 17 || this.caso.plazos[j] == 18 || this.caso.plazos[j] == 19 || this.caso.plazos[j] == 20)) || (this.caso.programa == 1 && esquemas.includes(2) && bancos.includes(21) && (destinoId == 5 || destinoId == 37) && (this.caso.plazos[j] == 5 || this.caso.plazos[j] == 6 || this.caso.plazos[j] == 7 || this.caso.plazos[j] == 8 || this.caso.plazos[j] == 9 || this.caso.plazos[j] == 10))) 
          {
            //console.log(this.caso.plazos)
            //alert(this.caso.plazos[j]+'-'+j)
            this.pruebaIon(this.caso.plazos[j], destinoId)

            // this.items = response.data.objContenido
            this.items.sort((a, b) => (a.MensualidadInicialPromedio > b.MensualidadInicialPromedio ? 1 : b.MensualidadInicialPromedio > a.MensualidadInicialPromedio ? -1 : 0))
            this.filtroName = 'Mensualidad Inicial'
            this.filtroId = 1
            /*if(this.destino.Id == 19){
                        for (var i = 0; i < this.items.length; i++) {
                          this.items[i].MejoraMensualidad= this.items[i].MejoraMensualidad
                          this.items[i].AhorroTotal= this.items[i].AhorroTotal
                        }
                      }*/

            this.$vs.loading({
              container: '#cotizacion',
              scale: 0.6,
            })
          } else {
            ////////////////////////////anali

            //  this.items= response.data.objContenido
            this.items.sort((a, b) => (a.MensualidadInicialPromedio > b.MensualidadInicialPromedio ? 1 : b.MensualidadInicialPromedio > a.MensualidadInicialPromedio ? -1 : 0))
            this.filtroName = 'Mensualidad Inicial'
            this.filtroId = 1
            if (this.destino.Id == 19) {
              for (var i = 0; i < this.items.length; i++) {
                this.items[i].MejoraMensualidad = this.items[i].MejoraMensualidad
                this.items[i].AhorroTotal = this.items[i].AhorroTotal
              }
            }

            // monto adicional de santander
            let factorAdicionalSantander = 0

            for (var i = 0; i < this.items.length; i++) {
              if (
                this.items[i].Variante == 32 ||
                this.items[i].Variante == 4 ||
                this.items[i].Variante == 6 ||
                this.items[i].Variante == 14 ||
                this.items[i].Variante == 21 ||
                this.items[i].Variante == 22 ||
                this.items[i].Variante == 26 ||
                this.items[i].Variante == 30 ||
                this.items[i].Variante == 31 ||
                this.items[i].Variante == 35 ||
                this.items[i].Variante == 37 ||
                this.items[i].Variante == 40 ||
                this.items[i].Variante == 51 ||
                this.items[i].Variante == 65 ||
                this.items[i].Variante == 133 ||
                this.items[i].Variante == 138 ||
                this.items[i].Variante == 171 ||
                this.items[i].Variante == 176 ||
                this.items[i].Variante == 184 ||
                this.items[i].Variante == 194 ||
                this.items[i].Variante == 197 ||
                this.items[i].Variante == 198 ||
                this.items[i].Variante == 9 ||
                this.items[i].Variante == 71 ||
                this.items[i].Variante == 72 ||
                this.items[i].Variante == 102 ||
                this.items[i].Variante == 103 ||
                this.items[i].Variante == 154 ||
                this.items[i].Variante == 75 ||
                this.items[i].Variante == 129 ||
                this.items[i].Variante == 131 ||
                this.items[i].Variante == 139 ||
                this.items[i].Variante == 175 ||
                this.items[i].Variante == 11 ||
                this.items[i].Variante == 17 ||
                this.items[i].Variante == 23 ||
                this.items[i].Variante == 24 ||
                this.items[i].Variante == 39 ||
                this.items[i].Variante == 96 ||
                this.items[i].Variante == 109 ||
                this.items[i].Variante == 144 ||
                this.items[i].Variante == 182 ||
                this.items[i].Variante == 183 ||
                this.items[i].Variante == 189 ||
                this.items[i].Variante == 190 ||
                this.items[i].Variante == 193 ||
                this.items[i].Variante == 10 ||
                this.items[i].Variante == 66 ||
                this.items[i].Variante == 67 ||
                this.items[i].Variante == 85 ||
                this.items[i].Variante == 97 ||
                this.items[i].Variante == 98 ||
                this.items[i].Variante == 168 ||
                this.items[i].Variante == 82 ||
                this.items[i].Variante == 132 ||
                this.items[i].Variante == 7 ||
                this.items[i].Variante == 69 ||
                this.items[i].Variante == 73 ||
                this.items[i].Variante == 81 ||
                this.items[i].Variante == 84 ||
                this.items[i].Variante == 104 ||
                this.items[i].Variante == 105 ||
                this.items[i].Variante == 166 ||
                this.items[i].Variante == 202 ||
                this.items[i].Variante == 203 ||
                this.items[i].Variante == 204 ||
                this.items[i].Variante == 205 ||
                this.items[i].Variante == 206 ||
                this.items[i].Variante == 208 ||
                this.items[i].Variante == 211 ||
                this.items[i].Variante == 212
              ) {
                console.log(this.items[i].Variante)
                this.items[i].Enganche = 0
              }

              /////validara si son los productos 4 y 171 ya q tienen promocion de gastos en 0
              if (this.items[i].Variante == 4 || this.items[i].Variante == 171) {
                this.items[i].GastosNotariales = 0
              } else if (this.items[i].Variante == 182 || this.items[i].Variante == 183 || this.items[i].Variante == 190 || this.items[i].Variante == 32 || this.items[i].Variante == 189 || this.items[i].Variante == 11 || this.items[i].Variante == 39 || this.items[i].Variante == 17 || this.items[i].Variante == 23 || this.items[i].Variante == 24 || this.items[i].Variante == 96 || this.items[i].Variante == 109 || this.items[i].Variante == 144 || this.items[i].Variante == 167 || this.items[i].Variante == 168 || this.items[i].Variante == 170 || this.items[i].Variante == 40 || this.items[i].Variante == 66 || this.items[i].Variante == 67 || this.items[i].Variante == 71 || this.items[i].Variante == 72 || this.items[i].Variante == 85 || this.items[i].Variante == 9 || this.items[i].Variante == 10 || this.items[i].Variante == 97 || this.items[i].Variante == 98 || this.items[i].Variante == 102 || this.items[i].Variante == 103) {
                this.items[i].GastosNotariales = ((this.caso.gastosNotarial * this.items[i].FactorDescuentoGastosNotariales) / 100) * parseFloat(this.caso.montoSolicitado.replace(/,/g, ''))
              } else if (this.items[i].Variante == 19 || this.items[i].Variante == 20 || this.items[i].Variante == 25 || this.items[i].Variante == 163) {
                ////Scotiabank tiene promo de 20% de descuento

                this.items[i].GastosNotariales = ((this.caso.gastosNotarial * this.items[i].FactorDescuentoGastosNotariales) / 100) * parseFloat(this.caso.valorInmueble.replace(/,/g, ''))
              } else {
                // TODO: factor porcentual para santander (variantes solo existen en prod 191, 192)
                // TODO: para pruebas ( 156, 157 )
                factorAdicionalSantander = 0                

                //ID DE PRODUCTOS INTEGRALES

                if(this.items[i].Variante == 191 || this.items[i].Variante == 192){//PROD
                //if (this.items[i].Variante == 174 || this.items[i].Variante == 175){//DEV

                  factorAdicionalSantander = this.calculaFactorSantander(this.items[i].Aforo, this.items[i].Plazo / 12, this.items[i].Monto)
                }

                this.items[i].GastosNotariales = ((this.caso.gastosNotarial * this.items[i].FactorDescuentoGastosNotariales) / 100) * parseFloat(this.caso.valorInmueble.replace(/,/g, ''))
              }

              this.items[i].GastosIniciales = this.items[i].Avaluo + this.items[i].ComisionApertura + this.items[i].GastosNotariales + factorAdicionalSantander
            }
          }
        }
        ////////////////////////////**************

        ////////////////////////
        // console.log(this.caso.programa)
        // console.log(esquemas.includes(2))
        // console.log(this.bancos2.includes(33))
        // console.log(this.caso.destino)
        //  console.log(this.caso.estado)
        // console.log(this.caso.gastosNotarial)

        ///validamos si trae banco KREDI, cumple plazos y tipo de esquemas
        for (var j = 0; j < this.caso.plazos.length; j++) {           

          if (this.caso.programa == 1 && esquemas.includes(2) && this.bancos2.includes(33) && this.caso.destino == 1 && (this.caso.plazos[j] == 5 || this.caso.plazos[j] == 10 || this.caso.plazos[j] == 15 || this.caso.plazos[j] == 20)) {
            this.ValorInmuebleKredi = parseFloat(this.caso.valorInmueble.replace(/,/g, ''))
            this.EngancheKredi = parseFloat(this.caso.valorInmueble.replace(/,/g, '')) - parseFloat(this.caso.montoSolicitado.replace(/,/g, ''))
            this.PorcentajeEngancheKredi = (parseFloat(this.caso.montoSolicitado.replace(/,/g, '')) / parseFloat(this.caso.valorInmueble.replace(/,/g, ''))) * 100
            this.PlazosKredi = this.caso.plazos[j]
            this.FinanciamientoKredi = 'No'
            this.ComisionAperturaKredi = '0.02'
            this.EstadoKredi = this.caso.estado
            this.GastosNotarialesKredi = this.caso.gastosNotarial / 100

            this.RegistraCasoKredi(this.caso.destino, this.ValorInmuebleKredi, this.EngancheKredi, this.PorcentajeEngancheKredi, this.PlazosKredi, this.FinanciamientoKredi, this.ComisionAperturaKredi, this.EstadoKredi, this.GastosNotarialesKredi)            
          
            /* SE COMENTA POR REQUERIMIENTO :  Actualización en cotizaciones Kredi
            ////ahora validamos el estado dependiendo de los gastos notariales
            if ((this.caso.estado == 16 || this.caso.estado == 20 || this.caso.estado == 21 || this.caso.estado == 31) && this.caso.gastosNotarial == 5) {
              console.log("caso estado = " + this.caso.estado)
              this.RegistraCasoKredi(this.caso.destino, this.ValorInmuebleKredi, this.EngancheKredi, this.PorcentajeEngancheKredi, this.PlazosKredi, this.FinanciamientoKredi, this.ComisionAperturaKredi, this.EstadoKredi, this.GastosNotarialesKredi)
            } else if (this.caso.estado == 30 && (this.caso.gastosNotarial == 4 || this.caso.gastosNotarial == 5)) {
              this.RegistraCasoKredi(this.caso.destino, this.ValorInmuebleKredi, this.EngancheKredi, this.PorcentajeEngancheKredi, this.PlazosKredi, this.FinanciamientoKredi, this.ComisionAperturaKredi, this.EstadoKredi, this.GastosNotarialesKredi)
            } else if ((this.caso.estado == 7 || this.caso.estado == 10 || this.caso.estado == 17) && (this.caso.gastosNotarial == 5 || this.caso.gastosNotarial == 7)) {
              this.RegistraCasoKredi(this.caso.destino, this.ValorInmuebleKredi, this.EngancheKredi, this.PorcentajeEngancheKredi, this.PlazosKredi, this.FinanciamientoKredi, this.ComisionAperturaKredi, this.EstadoKredi, this.GastosNotarialesKredi)
            } else if ((this.caso.estado == 22 || this.caso.estado == 9) && (this.caso.gastosNotarial == 5 || this.caso.gastosNotarial == 8)) {
              this.RegistraCasoKredi(this.caso.destino, this.ValorInmuebleKredi, this.EngancheKredi, this.PorcentajeEngancheKredi, this.PlazosKredi, this.FinanciamientoKredi, this.ComisionAperturaKredi, this.EstadoKredi, this.GastosNotarialesKredi)
            } else if (this.caso.estado == 23 && (this.caso.gastosNotarial == 9 || this.caso.gastosNotarial == 5)) {
              this.RegistraCasoKredi(this.caso.destino, this.ValorInmuebleKredi, this.EngancheKredi, this.PorcentajeEngancheKredi, this.PlazosKredi, this.FinanciamientoKredi, this.ComisionAperturaKredi, this.EstadoKredi, this.GastosNotarialesKredi)
            } else if ((this.caso.estado == 1 || this.caso.estado == 2 || this.caso.estado == 3 || this.caso.estado == 4 || this.caso.estado == 5 || this.caso.estado == 6 || this.caso.estado == 8 || this.caso.estado == 11 || this.caso.estado == 12 || this.caso.estado == 13 || this.caso.estado == 14 || this.caso.estado == 15 || this.caso.estado == 18 || this.caso.estado == 19 || this.caso.estado == 24 || this.caso.estado == 25 || this.caso.estado == 26 || this.caso.estado == 27 || this.caso.estado == 28 || this.caso.estado == 29 || this.caso.estado == 32) && (this.caso.gastosNotarial == 6 || this.caso.gastosNotarial == 5)) {
              this.RegistraCasoKredi(this.caso.destino, this.ValorInmuebleKredi, this.EngancheKredi, this.PorcentajeEngancheKredi, this.PlazosKredi, this.FinanciamientoKredi, this.ComisionAperturaKredi, this.EstadoKredi, this.GastosNotarialesKredi)
            }
          */

            // this.items = response.data.objContenido

            this.items.sort((a, b) => (a.MensualidadInicialPromedio > b.MensualidadInicialPromedio ? 1 : b.MensualidadInicialPromedio > a.MensualidadInicialPromedio ? -1 : 0))
            this.filtroName = 'Mensualidad Inicial'
            this.filtroId = 1

            this.mostrarOpciones = true
            this.mostrarDatosCotizacion = false
            this.$vs.loading.close('#cotizacion > .con-vs-loading')
          } else if (esquemas.includes(2) && this.bancos2.includes(33) && this.caso.destino == 19 && (this.caso.plazos[j] == 5 || this.caso.plazos[j] == 10 || this.caso.plazos[j] == 15 || this.caso.plazos[j] == 20 || this.caso.plazos[j] == 25 || this.caso.plazos[j] == 30)) {
            this.ValorInmuebleKredi = parseFloat(this.caso.valorInmueble.replace(/,/g, ''))
            this.EngancheKredi = parseFloat(this.caso.valorInmueble.replace(/,/g, '')) - parseFloat(this.caso.montoSolicitado.replace(/,/g, ''))
            this.PorcentajeEngancheKredi = (parseFloat(this.caso.montoSolicitado.replace(/,/g, '')) / parseFloat(this.caso.valorInmueble.replace(/,/g, ''))) * 100
            this.PlazosKredi = this.caso.plazos[j]
            this.FinanciamientoKredi = 'No'
            this.ComisionAperturaKredi = '0.02'
            this.EstadoKredi = this.caso.estado
            this.GastosNotarialesKredi = this.caso.gastosNotarial / 100

            /* this.RegistraCasoKredi(this.caso.destino, this.ValorInmuebleKredi, this.EngancheKredi, this.PorcentajeEngancheKredi, this.PlazosKredi, this.FinanciamientoKredi, this.ComisionAperturaKredi, this.EstadoKredi, this.GastosNotarialesKredi)

             SE COMENTA POR REQUERIMIENTO :  Actualización en cotizaciones Kredi

                ////ahora validamos el estado dependiendo de los gastos notariales
                if ((this.caso.estado == 16 || this.caso.estado == 20 || this.caso.estado == 21 || this.caso.estado == 17 || this.caso.estado == 1 || this.caso.estado == 2 || this.caso.estado == 3 || this.caso.estado == 4 || this.caso.estado == 5 || this.caso.estado == 6 || this.caso.estado == 8 || this.caso.estado == 12 || this.caso.estado == 13 || this.caso.estado == 14 || this.caso.estado == 15 || this.caso.estado == 18 || this.caso.estado == 19 || this.caso.estado == 24 || this.caso.estado == 25 || this.caso.estado == 26 || this.caso.estado == 27 || this.caso.estado == 28 || this.caso.estado == 29 || this.caso.estado == 32) && (this.caso.gastosNotarial == 5 || this.caso.gastosNotarial == 3)) {
                  this.RegistraCasoKredi(this.caso.destino, this.ValorInmuebleKredi, this.EngancheKredi, this.PorcentajeEngancheKredi, this.PlazosKredi, this.FinanciamientoKredi, this.ComisionAperturaKredi, this.EstadoKredi, this.GastosNotarialesKredi)
                } else if ((this.caso.estado == 31 || this.caso.estado == 30) && (this.caso.gastosNotarial == 2 || this.caso.gastosNotarial == 5)) {
                  this.RegistraCasoKredi(this.caso.destino, this.ValorInmuebleKredi, this.EngancheKredi, this.PorcentajeEngancheKredi, this.PlazosKredi, this.FinanciamientoKredi, this.ComisionAperturaKredi, this.EstadoKredi, this.GastosNotarialesKredi)
                } else if ((this.caso.estado == 7 || this.caso.estado == 10 || this.caso.estado == 9 || this.caso.estado == 23 || this.caso.estado == 22 || this.caso.estado == 11) && (this.caso.gastosNotarial == 5 || this.caso.gastosNotarial == 4)) {
                  this.RegistraCasoKredi(this.caso.destino, this.ValorInmuebleKredi, this.EngancheKredi, this.PorcentajeEngancheKredi, this.PlazosKredi, this.FinanciamientoKredi, this.ComisionAperturaKredi, this.EstadoKredi, this.GastosNotarialesKredi)
                }
            */

            // this.items = response.data.objContenido

            // console.log(this.items)

            this.items.sort((a, b) => (a.MensualidadInicialPromedio > b.MensualidadInicialPromedio ? 1 : b.MensualidadInicialPromedio > a.MensualidadInicialPromedio ? -1 : 0))
            this.filtroName = 'Mensualidad Inicial'
            this.filtroId = 1

            this.mostrarOpciones = true
            this.mostrarDatosCotizacion = false
            this.$vs.loading.close('#cotizacion > .con-vs-loading')
          }
        }

        ////////////////////////

        // this.items = response.data.objContenido
        // monto adicional de santander
        let factorAdicionalSantander = 0

        for (var i = 0; i < this.items.length; i++) {
          if (
            this.items[i].Variante == 32 ||
            this.items[i].Variante == 4 ||
            this.items[i].Variante == 6 ||
            this.items[i].Variante == 14 ||
            this.items[i].Variante == 21 ||
            this.items[i].Variante == 22 ||
            this.items[i].Variante == 26 ||
            this.items[i].Variante == 30 ||
            this.items[i].Variante == 31 ||
            this.items[i].Variante == 35 ||
            this.items[i].Variante == 37 ||
            this.items[i].Variante == 40 ||
            this.items[i].Variante == 51 ||
            this.items[i].Variante == 65 ||
            this.items[i].Variante == 133 ||
            this.items[i].Variante == 138 ||
            this.items[i].Variante == 171 ||
            this.items[i].Variante == 176 ||
            this.items[i].Variante == 184 ||
            this.items[i].Variante == 194 ||
            this.items[i].Variante == 197 ||
            this.items[i].Variante == 198 ||
            this.items[i].Variante == 9 ||
            this.items[i].Variante == 71 ||
            this.items[i].Variante == 72 ||
            this.items[i].Variante == 102 ||
            this.items[i].Variante == 103 ||
            this.items[i].Variante == 154 ||
            this.items[i].Variante == 75 ||
            this.items[i].Variante == 129 ||
            this.items[i].Variante == 131 ||
            this.items[i].Variante == 139 ||
            this.items[i].Variante == 175 ||
            this.items[i].Variante == 11 ||
            this.items[i].Variante == 17 ||
            this.items[i].Variante == 23 ||
            this.items[i].Variante == 24 ||
            this.items[i].Variante == 39 ||
            this.items[i].Variante == 96 ||
            this.items[i].Variante == 109 ||
            this.items[i].Variante == 144 ||
            this.items[i].Variante == 182 ||
            this.items[i].Variante == 183 ||
            this.items[i].Variante == 189 ||
            this.items[i].Variante == 190 ||
            this.items[i].Variante == 193 ||
            this.items[i].Variante == 10 ||
            this.items[i].Variante == 66 ||
            this.items[i].Variante == 67 ||
            this.items[i].Variante == 85 ||
            this.items[i].Variante == 97 ||
            this.items[i].Variante == 98 ||
            this.items[i].Variante == 168 ||
            this.items[i].Variante == 82 ||
            this.items[i].Variante == 132 ||
            this.items[i].Variante == 7 ||
            this.items[i].Variante == 69 ||
            this.items[i].Variante == 73 ||
            this.items[i].Variante == 81 ||
            this.items[i].Variante == 84 ||
            this.items[i].Variante == 104 ||
            this.items[i].Variante == 105 ||
            this.items[i].Variante == 166 ||
            this.items[i].Variante == 202 ||
            this.items[i].Variante == 203 ||
            this.items[i].Variante == 204 ||
            this.items[i].Variante == 205 ||
            this.items[i].Variante == 206 ||
            this.items[i].Variante == 208 ||
            this.items[i].Variante == 211 ||
            this.items[i].Variante == 212
          ) {
            this.items[i].Enganche = 0
          }

          /////validara si son los productos 4 y 171 ya q tienen promocion de gastos en 0
          if (this.items[i].Variante == 4 || this.items[i].Variante == 171) {
            this.items[i].GastosNotariales = 0
          } else if (this.items[i].Variante == 182 || this.items[i].Variante == 183 || this.items[i].Variante == 190 || this.items[i].Variante == 32 || this.items[i].Variante == 189 || this.items[i].Variante == 11 || this.items[i].Variante == 39 || this.items[i].Variante == 17 || this.items[i].Variante == 23 || this.items[i].Variante == 24 || this.items[i].Variante == 96 || this.items[i].Variante == 109 || this.items[i].Variante == 144 || this.items[i].Variante == 167 || this.items[i].Variante == 168 || this.items[i].Variante == 170 || this.items[i].Variante == 40 || this.items[i].Variante == 66 || this.items[i].Variante == 67 || this.items[i].Variante == 71 || this.items[i].Variante == 72 || this.items[i].Variante == 85 || this.items[i].Variante == 9 || this.items[i].Variante == 10 || this.items[i].Variante == 97 || this.items[i].Variante == 98 || this.items[i].Variante == 102 || this.items[i].Variante == 103) {
            this.items[i].GastosNotariales = ((this.caso.gastosNotarial * this.items[i].FactorDescuentoGastosNotariales) / 100) * parseFloat(this.caso.montoSolicitado.replace(/,/g, ''))
          } else if (this.items[i].Variante == 19 || this.items[i].Variante == 20 || this.items[i].Variante == 25 || this.items[i].Variante == 163) {
            ////Scotiabank tiene promo de 20% de descuento

            this.items[i].GastosNotariales = ((this.caso.gastosNotarial * this.items[i].FactorDescuentoGastosNotariales) / 100) * parseFloat(this.caso.valorInmueble.replace(/,/g, ''))
          } else {
              // TODO: factor porcentual para santander (variantes solo existen en prod 191, 192)
              // TODO: para pruebas ( 156, 157 )
              factorAdicionalSantander = 0

              if(this.items[i].Variante == 191 || this.items[i].Variante == 192){//PROD
              //if (this.items[i].Variante == 174 || this.items[i].Variante == 175){//DEV
                
                factorAdicionalSantander = this.calculaFactorSantander(this.items[i].Aforo, this.items[i].Plazo / 12, this.items[i].Monto)
              }

              this.items[i].GastosNotariales = ((this.caso.gastosNotarial * this.items[i].FactorDescuentoGastosNotariales) / 100) * parseFloat(this.caso.valorInmueble.replace(/,/g, ''))
          }

          this.items[i].GastosIniciales = this.items[i].Avaluo + this.items[i].ComisionApertura + this.items[i].GastosNotariales + factorAdicionalSantander
        }

        // this.items = response.data.objContenido
        this.items.sort((a, b) => (a.MensualidadInicialPromedio > b.MensualidadInicialPromedio ? 1 : b.MensualidadInicialPromedio > a.MensualidadInicialPromedio ? -1 : 0))
        this.filtroName = 'Mensualidad Inicial'
        this.filtroId = 1
        this.mostrarOpciones = true
        this.mostrarDatosCotizacion = false
        this.$vs.loading.close('#cotizacion > .con-vs-loading')

        if (localStorage.getItem('regreso')) {
          localStorage.removeItem('vistaNuevaCotizacion')
          localStorage.removeItem('regreso')
        }

        // this.items = response.data.objContenido
        //alert(this.caso.valorInmueble)
        for (var i = 0; i < this.items.length; i++) {
          if (this.caso.programa == 4 && this.destino == 1) {
            this.items[i].GastosInicialesCofinavit = parseFloat(this.caso.valorInmueble.replace(/,/g, '')) - this.caso.montoCredito + this.items[i].GastosIniciales - (parseFloat(this.caso.ssv.replace(/,/g, '')) + parseFloat(this.caso.creditoInf.replace(/,/g, '')))
          }
        }

        for (var i = 0; i < this.items.length; i++) {
          this.getTotalProducto(this.items[i])
        }

      } catch (error) {
        this.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      }

			// this.$vs.loading({
			// 	container: '#cotizacion',
			// 	scale: 0.6,
			// })

      // let self=this
     
      // let objRequestCotizaCaso = {
      //   strApiKey: this.key,
      //   strMetodo: 'Cotiza',
      //   objCaso: {
      //    Id: id,
      //    //GastoNotarial: this.caso.gastosNotarial
      //   }
      // }
      // this.$axios.post('/',objRequestCotizaCaso,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      // .then(
      //   response => {
      //     if (response.data.intEstatus == 0) {
      //       this.items = response.data.objContenido

      //       //Mejora ion
      //       let bancos = []
      //       let esquemas = []

      //       for (var i = 0; i < this.caso.bancos.length; i++) {
      //         if (this.caso.bancos[i].value) {
      //           bancos.push(parseInt(this.caso.bancos[i].value))
      //         } else {
      //           bancos.push(parseInt(this.caso.bancos[i]))
      //         }
      //       }

      //       for (var i = 0; i < this.caso.esquemas.length; i++) {
      //         if (this.caso.esquemas[i].value) {
      //           esquemas.push(parseInt(this.caso.esquemas[i].value))
      //         } else {
      //           esquemas.push(parseInt(this.caso.esquemas[i]))
      //         }
      //       }

      //       ////////////////////////////*************
      //       for (var j = 0; j < this.caso.plazos.length; j++) {
      //         if ((this.caso.programa == 1 && esquemas.includes(2) && bancos.includes(21) && (destinoId == 1 || destinoId == 3) && (this.caso.plazos[j] == 5 || this.caso.plazos[j] == 6 || this.caso.plazos[j] == 7 || this.caso.plazos[j] == 8 || this.caso.plazos[j] == 9 || this.caso.plazos[j] == 10 || this.caso.plazos[j] == 11 || this.caso.plazos[j] == 12 || this.caso.plazos[j] == 13 || this.caso.plazos[j] == 14 || this.caso.plazos[j] == 15 || this.caso.plazos[j] == 16 || this.caso.plazos[j] == 17 || this.caso.plazos[j] == 18 || this.caso.plazos[j] == 19 || this.caso.plazos[j] == 20)) || (this.caso.programa == 1 && esquemas.includes(2) && bancos.includes(21) && (destinoId == 5 || destinoId == 37) && (this.caso.plazos[j] == 5 || this.caso.plazos[j] == 6 || this.caso.plazos[j] == 7 || this.caso.plazos[j] == 8 || this.caso.plazos[j] == 9 || this.caso.plazos[j] == 10))) {
      //           //console.log(this.caso.plazos)
      //           //alert(this.caso.plazos[j]+'-'+j)
      //           this.pruebaIon(this.caso.plazos[j], destinoId)

      //           this.items = response.data.objContenido
      //           this.items.sort((a, b) => (a.MensualidadPromedio > b.MensualidadPromedio ? 1 : b.MensualidadPromedio > a.MensualidadPromedio ? -1 : 0))
      //           this.filtroName = 'Mensualidad Inicial'
      //           this.filtroId = 1
      //           /*if(this.destino.Id == 19){
      //                       for (var i = 0; i < this.items.length; i++) {
      //                         this.items[i].MejoraMensualidad= this.items[i].MejoraMensualidad
      //                         this.items[i].AhorroTotal= this.items[i].AhorroTotal
      //                       }
      //                     }*/

      //           this.$vs.loading({
      //             container: '#cotizacion',
      //             scale: 0.6,
      //           })
      //         } else {
      //           ////////////////////////////anali

      //           //  this.items= response.data.objContenido
      //           this.items.sort((a, b) => (a.MensualidadPromedio > b.MensualidadPromedio ? 1 : b.MensualidadPromedio > a.MensualidadPromedio ? -1 : 0))
      //           this.filtroName = 'Mensualidad Inicial'
      //           this.filtroId = 1
      //           if (this.destino.Id == 19) {
      //             for (var i = 0; i < this.items.length; i++) {
      //               this.items[i].MejoraMensualidad = this.items[i].MejoraMensualidad
      //               this.items[i].AhorroTotal = this.items[i].AhorroTotal
      //             }
      //           }

      //           // monto adicional de santander
      //           let factorAdicionalSantander = 0

      //           for (var i = 0; i < this.items.length; i++) {
      //             if (
      //               this.items[i].Variante == 32 ||
      //               this.items[i].Variante == 4 ||
      //               this.items[i].Variante == 6 ||
      //               this.items[i].Variante == 14 ||
      //               this.items[i].Variante == 21 ||
      //               this.items[i].Variante == 22 ||
      //               this.items[i].Variante == 26 ||
      //               this.items[i].Variante == 30 ||
      //               this.items[i].Variante == 31 ||
      //               this.items[i].Variante == 35 ||
      //               this.items[i].Variante == 37 ||
      //               this.items[i].Variante == 40 ||
      //               this.items[i].Variante == 51 ||
      //               this.items[i].Variante == 65 ||
      //               this.items[i].Variante == 133 ||
      //               this.items[i].Variante == 138 ||
      //               this.items[i].Variante == 171 ||
      //               this.items[i].Variante == 176 ||
      //               this.items[i].Variante == 184 ||
      //               this.items[i].Variante == 194 ||
      //               this.items[i].Variante == 197 ||
      //               this.items[i].Variante == 198 ||
      //               this.items[i].Variante == 9 ||
      //               this.items[i].Variante == 71 ||
      //               this.items[i].Variante == 72 ||
      //               this.items[i].Variante == 102 ||
      //               this.items[i].Variante == 103 ||
      //               this.items[i].Variante == 154 ||
      //               this.items[i].Variante == 75 ||
      //               this.items[i].Variante == 129 ||
      //               this.items[i].Variante == 131 ||
      //               this.items[i].Variante == 139 ||
      //               this.items[i].Variante == 175 ||
      //               this.items[i].Variante == 11 ||
      //               this.items[i].Variante == 17 ||
      //               this.items[i].Variante == 23 ||
      //               this.items[i].Variante == 24 ||
      //               this.items[i].Variante == 39 ||
      //               this.items[i].Variante == 96 ||
      //               this.items[i].Variante == 109 ||
      //               this.items[i].Variante == 144 ||
      //               this.items[i].Variante == 182 ||
      //               this.items[i].Variante == 183 ||
      //               this.items[i].Variante == 189 ||
      //               this.items[i].Variante == 190 ||
      //               this.items[i].Variante == 193 ||
      //               this.items[i].Variante == 10 ||
      //               this.items[i].Variante == 66 ||
      //               this.items[i].Variante == 67 ||
      //               this.items[i].Variante == 85 ||
      //               this.items[i].Variante == 97 ||
      //               this.items[i].Variante == 98 ||
      //               this.items[i].Variante == 168 ||
      //               this.items[i].Variante == 82 ||
      //               this.items[i].Variante == 132 ||
      //               this.items[i].Variante == 7 ||
      //               this.items[i].Variante == 69 ||
      //               this.items[i].Variante == 73 ||
      //               this.items[i].Variante == 81 ||
      //               this.items[i].Variante == 84 ||
      //               this.items[i].Variante == 104 ||
      //               this.items[i].Variante == 105 ||
      //               this.items[i].Variante == 166 ||
      //               this.items[i].Variante == 202 ||
      //               this.items[i].Variante == 203 ||
      //               this.items[i].Variante == 204 ||
      //               this.items[i].Variante == 205 ||
      //               this.items[i].Variante == 206 ||
      //               this.items[i].Variante == 208 ||
      //               this.items[i].Variante == 211 ||
      //               this.items[i].Variante == 212
      //             ) {
      //               console.log(this.items[i].Variante)
      //               this.items[i].Enganche = 0
      //             }

      //             /////validara si son los productos 4 y 171 ya q tienen promocion de gastos en 0
      //             if (this.items[i].Variante == 4 || this.items[i].Variante == 171) {
      //               this.items[i].GastosNotariales = 0
      //             } else if (this.items[i].Variante == 182 || this.items[i].Variante == 183 || this.items[i].Variante == 190 || this.items[i].Variante == 32 || this.items[i].Variante == 189 || this.items[i].Variante == 11 || this.items[i].Variante == 39 || this.items[i].Variante == 17 || this.items[i].Variante == 23 || this.items[i].Variante == 24 || this.items[i].Variante == 96 || this.items[i].Variante == 109 || this.items[i].Variante == 144 || this.items[i].Variante == 167 || this.items[i].Variante == 168 || this.items[i].Variante == 170 || this.items[i].Variante == 40 || this.items[i].Variante == 66 || this.items[i].Variante == 67 || this.items[i].Variante == 71 || this.items[i].Variante == 72 || this.items[i].Variante == 85 || this.items[i].Variante == 9 || this.items[i].Variante == 10 || this.items[i].Variante == 97 || this.items[i].Variante == 98 || this.items[i].Variante == 102 || this.items[i].Variante == 103) {
      //               this.items[i].GastosNotariales = ((this.caso.gastosNotarial * this.items[i].FactorDescuentoGastosNotariales) / 100) * parseFloat(this.caso.montoSolicitado.replace(/,/g, ''))
      //             } else if (this.items[i].Variante == 19 || this.items[i].Variante == 20 || this.items[i].Variante == 25 || this.items[i].Variante == 163) {
      //               ////Scotiabank tiene promo de 20% de descuento

      //               this.items[i].GastosNotariales = ((this.caso.gastosNotarial * this.items[i].FactorDescuentoGastosNotariales) / 100) * parseFloat(this.caso.valorInmueble.replace(/,/g, '')) * 0.8
      //             } else {
      //               // TODO: factor porcentual para santander (variantes solo existen en prod 191, 192)
      //               // TODO: para pruebas ( 156, 157 )
      //               factorAdicionalSantander = 0

      //               //if(this.items[i].Variante == 191 || this.items[i].Variante == 192) {  //PROD
      //               if (this.items[i].Variante == 174 || this.items[i].Variante == 175) {
      //                 //DEV
      //                 factorAdicionalSantander = this.calculaFactorSantander(this.items[i].Aforo, this.items[i].Plazo / 12, this.items[i].Monto)
      //               }

      //               this.items[i].GastosNotariales = ((this.caso.gastosNotarial * this.items[i].FactorDescuentoGastosNotariales) / 100) * parseFloat(this.caso.valorInmueble.replace(/,/g, ''))
      //             }

      //             this.items[i].GastosIniciales = this.items[i].Avaluo + this.items[i].ComisionApertura + this.items[i].GastosNotariales + factorAdicionalSantander
      //           }
      //         }
      //       }
      //       ////////////////////////////**************

      //       ////////////////////////
      //       // console.log(this.caso.programa)
      //       // console.log(esquemas.includes(2))
      //       // console.log(this.bancos2.includes(33))
      //       // console.log(this.caso.destino)
      //       //  console.log(this.caso.estado)
      //       // console.log(this.caso.gastosNotarial)

      //       ///validamos si trae banco KREDI, cumple plazos y tipo de esquemas
      //       for (var j = 0; j < this.caso.plazos.length; j++) {
      //         if (this.caso.programa == 1 && esquemas.includes(2) && this.bancos2.includes(33) && this.caso.destino == 1 && (this.caso.plazos[j] == 5 || this.caso.plazos[j] == 10 || this.caso.plazos[j] == 15 || this.caso.plazos[j] == 20)) {
      //           this.ValorInmuebleKredi = parseFloat(this.caso.valorInmueble.replace(/,/g, ''))
      //           this.EngancheKredi = parseFloat(this.caso.valorInmueble.replace(/,/g, '')) - parseFloat(this.caso.montoSolicitado.replace(/,/g, ''))
      //           this.PorcentajeEngancheKredi = (parseFloat(this.caso.montoSolicitado.replace(/,/g, '')) / parseFloat(this.caso.valorInmueble.replace(/,/g, ''))) * 100
      //           this.PlazosKredi = this.caso.plazos[j]
      //           this.FinanciamientoKredi = 'No'
      //           this.ComisionAperturaKredi = '0.0'
      //           this.EstadoKredi = this.caso.estado
      //           this.GastosNotarialesKredi = this.caso.gastosNotarial / 100

      //           ////ahora validamos el estado dependiendo de los gastos notariales
      //           if ((this.caso.estado == 16 || this.caso.estado == 20 || this.caso.estado == 21 || this.caso.estado == 31) && this.caso.gastosNotarial == 5) {
      //             this.RegistraCasoKredi(this.caso.destino, this.ValorInmuebleKredi, this.EngancheKredi, this.PorcentajeEngancheKredi, this.PlazosKredi, this.FinanciamientoKredi, this.ComisionAperturaKredi, this.EstadoKredi, this.GastosNotarialesKredi)
      //           } else if (this.caso.estado == 30 && (this.caso.gastosNotarial == 4 || this.caso.gastosNotarial == 5)) {
      //             this.RegistraCasoKredi(this.caso.destino, this.ValorInmuebleKredi, this.EngancheKredi, this.PorcentajeEngancheKredi, this.PlazosKredi, this.FinanciamientoKredi, this.ComisionAperturaKredi, this.EstadoKredi, this.GastosNotarialesKredi)
      //           } else if ((this.caso.estado == 7 || this.caso.estado == 10 || this.caso.estado == 17) && (this.caso.gastosNotarial == 5 || this.caso.gastosNotarial == 7)) {
      //             this.RegistraCasoKredi(this.caso.destino, this.ValorInmuebleKredi, this.EngancheKredi, this.PorcentajeEngancheKredi, this.PlazosKredi, this.FinanciamientoKredi, this.ComisionAperturaKredi, this.EstadoKredi, this.GastosNotarialesKredi)
      //           } else if ((this.caso.estado == 22 || this.caso.estado == 9) && (this.caso.gastosNotarial == 5 || this.caso.gastosNotarial == 8)) {
      //             this.RegistraCasoKredi(this.caso.destino, this.ValorInmuebleKredi, this.EngancheKredi, this.PorcentajeEngancheKredi, this.PlazosKredi, this.FinanciamientoKredi, this.ComisionAperturaKredi, this.EstadoKredi, this.GastosNotarialesKredi)
      //           } else if (this.caso.estado == 23 && (this.caso.gastosNotarial == 9 || this.caso.gastosNotarial == 5)) {
      //             this.RegistraCasoKredi(this.caso.destino, this.ValorInmuebleKredi, this.EngancheKredi, this.PorcentajeEngancheKredi, this.PlazosKredi, this.FinanciamientoKredi, this.ComisionAperturaKredi, this.EstadoKredi, this.GastosNotarialesKredi)
      //           } else if ((this.caso.estado == 1 || this.caso.estado == 2 || this.caso.estado == 3 || this.caso.estado == 4 || this.caso.estado == 5 || this.caso.estado == 6 || this.caso.estado == 8 || this.caso.estado == 11 || this.caso.estado == 12 || this.caso.estado == 13 || this.caso.estado == 14 || this.caso.estado == 15 || this.caso.estado == 18 || this.caso.estado == 19 || this.caso.estado == 24 || this.caso.estado == 25 || this.caso.estado == 26 || this.caso.estado == 27 || this.caso.estado == 28 || this.caso.estado == 29 || this.caso.estado == 32) && (this.caso.gastosNotarial == 6 || this.caso.gastosNotarial == 5)) {
      //             this.RegistraCasoKredi(this.caso.destino, this.ValorInmuebleKredi, this.EngancheKredi, this.PorcentajeEngancheKredi, this.PlazosKredi, this.FinanciamientoKredi, this.ComisionAperturaKredi, this.EstadoKredi, this.GastosNotarialesKredi)
      //           }

      //           this.items = response.data.objContenido

      //           this.items.sort((a, b) => (a.MensualidadPromedio > b.MensualidadPromedio ? 1 : b.MensualidadPromedio > a.MensualidadPromedio ? -1 : 0))
      //           this.filtroName = 'Mensualidad Inicial'
      //           this.filtroId = 1

      //           this.mostrarOpciones = true
      //           this.mostrarDatosCotizacion = false
      //           this.$vs.loading.close('#cotizacion > .con-vs-loading')
      //         } else if (esquemas.includes(2) && this.bancos2.includes(33) && this.caso.destino == 19 && (this.caso.plazos[j] == 5 || this.caso.plazos[j] == 10 || this.caso.plazos[j] == 15 || this.caso.plazos[j] == 20 || this.caso.plazos[j] == 25 || this.caso.plazos[j] == 30)) {
      //           this.ValorInmuebleKredi = parseFloat(this.caso.valorInmueble.replace(/,/g, ''))
      //           this.EngancheKredi = parseFloat(this.caso.valorInmueble.replace(/,/g, '')) - parseFloat(this.caso.montoSolicitado.replace(/,/g, ''))
      //           this.PorcentajeEngancheKredi = (parseFloat(this.caso.montoSolicitado.replace(/,/g, '')) / parseFloat(this.caso.valorInmueble.replace(/,/g, ''))) * 100
      //           this.PlazosKredi = this.caso.plazos[j]
      //           this.FinanciamientoKredi = 'No'
      //           this.ComisionAperturaKredi = '0.0'
      //           this.EstadoKredi = this.caso.estado
      //           this.GastosNotarialesKredi = this.caso.gastosNotarial / 100

      //           ////ahora validamos el estado dependiendo de los gastos notariales
      //           if ((this.caso.estado == 16 || this.caso.estado == 20 || this.caso.estado == 21 || this.caso.estado == 17 || this.caso.estado == 1 || this.caso.estado == 2 || this.caso.estado == 3 || this.caso.estado == 4 || this.caso.estado == 5 || this.caso.estado == 6 || this.caso.estado == 8 || this.caso.estado == 12 || this.caso.estado == 13 || this.caso.estado == 14 || this.caso.estado == 15 || this.caso.estado == 18 || this.caso.estado == 19 || this.caso.estado == 24 || this.caso.estado == 25 || this.caso.estado == 26 || this.caso.estado == 27 || this.caso.estado == 28 || this.caso.estado == 29 || this.caso.estado == 32) && (this.caso.gastosNotarial == 5 || this.caso.gastosNotarial == 3)) {
      //             this.RegistraCasoKredi(this.caso.destino, this.ValorInmuebleKredi, this.EngancheKredi, this.PorcentajeEngancheKredi, this.PlazosKredi, this.FinanciamientoKredi, this.ComisionAperturaKredi, this.EstadoKredi, this.GastosNotarialesKredi)
      //           } else if ((this.caso.estado == 31 || this.caso.estado == 30) && (this.caso.gastosNotarial == 2 || this.caso.gastosNotarial == 5)) {
      //             this.RegistraCasoKredi(this.caso.destino, this.ValorInmuebleKredi, this.EngancheKredi, this.PorcentajeEngancheKredi, this.PlazosKredi, this.FinanciamientoKredi, this.ComisionAperturaKredi, this.EstadoKredi, this.GastosNotarialesKredi)
      //           } else if ((this.caso.estado == 7 || this.caso.estado == 10 || this.caso.estado == 9 || this.caso.estado == 23 || this.caso.estado == 22 || this.caso.estado == 11) && (this.caso.gastosNotarial == 5 || this.caso.gastosNotarial == 4)) {
      //             this.RegistraCasoKredi(this.caso.destino, this.ValorInmuebleKredi, this.EngancheKredi, this.PorcentajeEngancheKredi, this.PlazosKredi, this.FinanciamientoKredi, this.ComisionAperturaKredi, this.EstadoKredi, this.GastosNotarialesKredi)
      //           }

      //           this.items = response.data.objContenido

      //           // console.log(this.items)

      //           this.items.sort((a, b) => (a.MensualidadPromedio > b.MensualidadPromedio ? 1 : b.MensualidadPromedio > a.MensualidadPromedio ? -1 : 0))
      //           this.filtroName = 'Mensualidad Inicial'
      //           this.filtroId = 1

      //           this.mostrarOpciones = true
      //           this.mostrarDatosCotizacion = false
      //           this.$vs.loading.close('#cotizacion > .con-vs-loading')
      //         }
      //       }

      //       ////////////////////////

      //       this.items = response.data.objContenido
      //       // monto adicional de santander
      //       let factorAdicionalSantander = 0

      //       for (var i = 0; i < this.items.length; i++) {
      //         if (
      //           this.items[i].Variante == 32 ||
      //           this.items[i].Variante == 4 ||
      //           this.items[i].Variante == 6 ||
      //           this.items[i].Variante == 14 ||
      //           this.items[i].Variante == 21 ||
      //           this.items[i].Variante == 22 ||
      //           this.items[i].Variante == 26 ||
      //           this.items[i].Variante == 30 ||
      //           this.items[i].Variante == 31 ||
      //           this.items[i].Variante == 35 ||
      //           this.items[i].Variante == 37 ||
      //           this.items[i].Variante == 40 ||
      //           this.items[i].Variante == 51 ||
      //           this.items[i].Variante == 65 ||
      //           this.items[i].Variante == 133 ||
      //           this.items[i].Variante == 138 ||
      //           this.items[i].Variante == 171 ||
      //           this.items[i].Variante == 176 ||
      //           this.items[i].Variante == 184 ||
      //           this.items[i].Variante == 194 ||
      //           this.items[i].Variante == 197 ||
      //           this.items[i].Variante == 198 ||
      //           this.items[i].Variante == 9 ||
      //           this.items[i].Variante == 71 ||
      //           this.items[i].Variante == 72 ||
      //           this.items[i].Variante == 102 ||
      //           this.items[i].Variante == 103 ||
      //           this.items[i].Variante == 154 ||
      //           this.items[i].Variante == 75 ||
      //           this.items[i].Variante == 129 ||
      //           this.items[i].Variante == 131 ||
      //           this.items[i].Variante == 139 ||
      //           this.items[i].Variante == 175 ||
      //           this.items[i].Variante == 11 ||
      //           this.items[i].Variante == 17 ||
      //           this.items[i].Variante == 23 ||
      //           this.items[i].Variante == 24 ||
      //           this.items[i].Variante == 39 ||
      //           this.items[i].Variante == 96 ||
      //           this.items[i].Variante == 109 ||
      //           this.items[i].Variante == 144 ||
      //           this.items[i].Variante == 182 ||
      //           this.items[i].Variante == 183 ||
      //           this.items[i].Variante == 189 ||
      //           this.items[i].Variante == 190 ||
      //           this.items[i].Variante == 193 ||
      //           this.items[i].Variante == 10 ||
      //           this.items[i].Variante == 66 ||
      //           this.items[i].Variante == 67 ||
      //           this.items[i].Variante == 85 ||
      //           this.items[i].Variante == 97 ||
      //           this.items[i].Variante == 98 ||
      //           this.items[i].Variante == 168 ||
      //           this.items[i].Variante == 82 ||
      //           this.items[i].Variante == 132 ||
      //           this.items[i].Variante == 7 ||
      //           this.items[i].Variante == 69 ||
      //           this.items[i].Variante == 73 ||
      //           this.items[i].Variante == 81 ||
      //           this.items[i].Variante == 84 ||
      //           this.items[i].Variante == 104 ||
      //           this.items[i].Variante == 105 ||
      //           this.items[i].Variante == 166 ||
      //           this.items[i].Variante == 202 ||
      //           this.items[i].Variante == 203 ||
      //           this.items[i].Variante == 204 ||
      //           this.items[i].Variante == 205 ||
      //           this.items[i].Variante == 206 ||
      //           this.items[i].Variante == 208 ||
      //           this.items[i].Variante == 211 ||
      //           this.items[i].Variante == 212
      //         ) {
      //           this.items[i].Enganche = 0
      //         }

      //         /////validara si son los productos 4 y 171 ya q tienen promocion de gastos en 0
      //         if (this.items[i].Variante == 4 || this.items[i].Variante == 171) {
      //           this.items[i].GastosNotariales = 0
      //         } else if (this.items[i].Variante == 182 || this.items[i].Variante == 183 || this.items[i].Variante == 190 || this.items[i].Variante == 32 || this.items[i].Variante == 189 || this.items[i].Variante == 11 || this.items[i].Variante == 39 || this.items[i].Variante == 17 || this.items[i].Variante == 23 || this.items[i].Variante == 24 || this.items[i].Variante == 96 || this.items[i].Variante == 109 || this.items[i].Variante == 144 || this.items[i].Variante == 167 || this.items[i].Variante == 168 || this.items[i].Variante == 170 || this.items[i].Variante == 40 || this.items[i].Variante == 66 || this.items[i].Variante == 67 || this.items[i].Variante == 71 || this.items[i].Variante == 72 || this.items[i].Variante == 85 || this.items[i].Variante == 9 || this.items[i].Variante == 10 || this.items[i].Variante == 97 || this.items[i].Variante == 98 || this.items[i].Variante == 102 || this.items[i].Variante == 103) {
      //           this.items[i].GastosNotariales = ((this.caso.gastosNotarial * this.items[i].FactorDescuentoGastosNotariales) / 100) * parseFloat(this.caso.montoSolicitado.replace(/,/g, ''))
      //         } else if (this.items[i].Variante == 19 || this.items[i].Variante == 20 || this.items[i].Variante == 25 || this.items[i].Variante == 163) {
      //           ////Scotiabank tiene promo de 20% de descuento

      //           this.items[i].GastosNotariales = ((this.caso.gastosNotarial * this.items[i].FactorDescuentoGastosNotariales) / 100) * parseFloat(this.caso.valorInmueble.replace(/,/g, '')) * 0.8
      //         } else {
      //           // TODO: factor porcentual para santander (variantes solo existen en prod 191, 192)
      //           // TODO: para pruebas ( 156, 157 )
      //           factorAdicionalSantander = 0

      //           //if(this.items[i].Variante == 191 || this.items[i].Variante == 192) {  // PROD
      //           if (this.items[i].Variante == 174 || this.items[i].Variante == 175) {
      //             // DEV
      //             factorAdicionalSantander = this.calculaFactorSantander(this.items[i].Aforo, this.items[i].Plazo / 12, this.items[i].Monto)
      //           }

      //           this.items[i].GastosNotariales = ((this.caso.gastosNotarial * this.items[i].FactorDescuentoGastosNotariales) / 100) * parseFloat(this.caso.valorInmueble.replace(/,/g, ''))
      //         }

      //         this.items[i].GastosIniciales = this.items[i].Avaluo + this.items[i].ComisionApertura + this.items[i].GastosNotariales + factorAdicionalSantander
      //       }

      //       this.items = response.data.objContenido
      //       this.items.sort((a, b) => (a.MensualidadPromedio > b.MensualidadPromedio ? 1 : b.MensualidadPromedio > a.MensualidadPromedio ? -1 : 0))
      //       this.filtroName = 'Mensualidad Inicial'
      //       this.filtroId = 1
      //       this.mostrarOpciones = true
      //       this.mostrarDatosCotizacion = false
      //       this.$vs.loading.close('#cotizacion > .con-vs-loading')
      //       if (localStorage.getItem('regreso')) {
      //         localStorage.removeItem('vistaNuevaCotizacion')
      //         localStorage.removeItem('regreso')
      //       }

      //       this.items = response.data.objContenido
      //       //alert(this.caso.valorInmueble)
      //       for (var i = 0; i < this.items.length; i++) {
      //         if (this.caso.programa == 4 && this.destino == 1) {
      //           this.items[i].GastosInicialesCofinavit = parseFloat(this.caso.valorInmueble.replace(/,/g, '')) - this.caso.montoCredito + this.items[i].GastosIniciales - (parseFloat(this.caso.ssv.replace(/,/g, '')) + parseFloat(this.caso.creditoInf.replace(/,/g, '')))
      //         }
      //       }
      //     } else {
      //       this.$vs.notify({
      //         title: 'Ocurrio un error en Cotiza',
      //         text: response.data.strError,
      //         color: 'danger',
      //         position: 'top-right',
      //       })
      //     }

      //   }
      // ).catch(function (error) {
      //   self.$vs.notify({
      //     title:'Ocurrio un error de sistema',
      //     text:error,
      //     color:'danger',
      //     position:'top-right'
      //   })
      // })
    },

    getTotalProducto(item) {      

			let self = this
			if (this.id > 0) {
				this.idCaso = this.id
			}
			let TablaPagosIon = ''
			let AmortizacionPdf = ''
			let AmortizacionJson = ''
			if (item.BancoId == 21) {
				TablaPagosIon = true
				AmortizacionPdf = item.PdfAmortizacion
				AmortizacionJson = item.JsonAmortizacion
			}
			let objRequestDameTablaPagos = {
				strApiKey: this.key,
				strMetodo: 'DameTablaPagos',
				objResultado: {
					Id: item.Id,
					caso: this.id,
					caso: this.idCaso,
					IonAmortizacion: TablaPagosIon,
					pdfIon: AmortizacionPdf,
					jsonIon: AmortizacionJson,
					plazo: item.Plazo,
					tasa: item.Tasa,
				},
			}

			this.$axios
				.post('/', objRequestDameTablaPagos, {
					headers: {
						'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
					},
				})
				.then((response) => {
					if (response.data.intEstatus != 0) {
						return this.$vs.notify({
							title: 'Ocurrio un error en DameTablaPagos',
							text: response.data.strError,
							color: 'danger',
							position: 'top-right',
						})
					}

					let pagoMensualTotal = 0
					this.itemsTable = response.data.objContenido
          
					for (var i = 0; i < this.itemsTable.length; i++) {            
						pagoMensualTotal = pagoMensualTotal + this.itemsTable[i].PagoMensual
					}
          item.PagoTotal = pagoMensualTotal        
				})
				.catch(function (error) {
					self.$vs.notify({
						title: 'Ocurrio un error de sistema',
						text: error,
						color: 'danger',
						position: 'top-right',
					})
				})
		},


    async getDataDestino () {
      const destino = this.caso.destino

      const objRequestListaDestinos = {
        strApiKey: this.key,
        strMetodo: 'DameDatosDestino',
        objDestino: {
          id: destino
        }
      }

      const resp = await this.$axios.post('/',objRequestListaDestinos,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      const { intEstatus, data } = resp

      if ( intEstatus ) {
        this.$vs.notify({
          title: 'Ocurrio un error en DameDatosDestino',
          text: data.strError,
          color: 'danger',
          position: 'top-right',
        })
      }

      const { objContenido } = data

      // console.log('objContenido.Aforo :>> ', objContenido.Aforo)
      this.PorcentajeMaximo = 97  /////objContenido ? objContenido.Aforo : 98

    },

    updateCaso(id){      
      //Ministraciones

				let porcentajes = ''        

        if (this.usoMinistraciones == 'Si') {
          porcentajes = this.camposID.name[1]

          for (let p = 2; p <= this.campos; p++) {													
            porcentajes = porcentajes + ',' + this.camposID.name[p]
          }
          porcentajes = porcentajes + ','
          
        }else{
          this.plazoContruccion = ''
          this.periodo = ''	
          this.campos = ''				
        }

      // quitar comas numeros
      let aportaVol = '';
      let numAporte = '';
      if (this.hasAportacion == "Si") {
        aportaVol = this.aportacionVoluntaria;
        numAporte = this.numeroAportacion;
        
        aportaVol = parseFloat(aportaVol.replaceAll(',', ''));

        if (typeof(numAporte) == "string") {
          numAporte = parseInt(numAporte.replaceAll(',', ''));
        }
      }

      let self=this
      let objRequestRegistraCaso1= {}
      let bancos=[]
      this.caso.montoCredito=this.caso.montoCredito.toString().replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      if(this.caso.montoCredito!==0 && this.caso.PrecalificacionTipo != 3){  //no usar replace cuando es 0
        var auxMontoCredito=parseFloat(this.caso.montoCredito.replace(/,/g,""))
        this.caso.montoCredito=auxMontoCredito
      }
      for (var i = 0; i < this.caso.bancos.length; i++) {
        bancos.push((this.caso.bancos[i].value).toString())
      }
      //if(this.caso.destino == '25' || this.caso.destino == '22' || this.caso.destino == '33' || this.caso.destino == '34'){
      if((this.caso.destino == '19' || this.caso.destino == '25' || this.caso.destino == '22' || this.caso.destino == '33' || this.caso.destino == '34')  && ( this.caso.programa == 6 || this.caso.programa == 1) ){
       objRequestRegistraCaso1 = {
          strApiKey: 'c7e0233e4a9c856d50662005a1c6cfbb',
          strMetodo: 'RegistraCaso',
          objCaso: {
            Id:id,
            Broker:'demoSoc@socasesores.com',
            ValorInmueble : parseFloat(this.caso.valorInmueble.replace(/,/g,"")),
            SaldoInsoluto : parseFloat(this.caso.montoSolicitado.replace(/,/g,"")),
            Plazos : this.caso.plazos,
            Solicitante: 'solicitante@socasesores.com',
            Destino : this.caso.destino,
            Esquemas:this.caso.esquemas,
            Programas:[this.caso.programa],
            PlazoRestante:this.caso.plazoRestante,
            PagoMensualActual: parseFloat(this.caso.pagoMensualActual.replace(/,/g,"")),
            Bancos:bancos,
            PerfilCliente : this.caso.tasa,
            VersionOrigen:'Web rapida',
            GastoNotarial: this.caso.gastosNotarial,
            PrecalificacionTipo: this.caso.PrecalificacionTipo
          },
          objAportacion: {
            // para validacion en el metodo
            hasAportacion: this.hasAportacion,
            // para bd
            prepagoAportacion: this.tipoPrepago,
            plazoAportacion: this.tipoPlazo,
            montoAportacion: aportaVol,
            cantidadAportaciones: numAporte,
          },
        }
      }else if((this.caso.destino == '19' || this.caso.destino == '25' || this.caso.destino == '22' || this.caso.destino == '33' || this.caso.destino == '34')  && ( this.caso.programa == 4 || this.caso.programa == 9) ){        
       objRequestRegistraCaso1 = {
          strApiKey: 'c7e0233e4a9c856d50662005a1c6cfbb',
          strMetodo: 'RegistraCaso',
          objCaso: {
            Id:id,
            Broker:'demoSoc@socasesores.com',
            ValorInmueble : parseFloat(this.caso.valorInmueble.replace(/,/g,"")),
            SaldoInsoluto : parseFloat(this.caso.montoSolicitado.replace(/,/g,"")),
            Plazos : this.caso.plazos,
            Solicitante: 'solicitante@socasesores.com',
            Destino : this.caso.destino,
            Esquemas:this.caso.esquemas,
            Programas:[this.caso.programa],
            PlazoRestante:this.caso.plazoRestante,
            PagoMensualActual: parseFloat(this.caso.pagoMensualActual.replace(/,/g,"")),
            CreditoInfonavit:parseFloat(this.caso.creditoInf.replace(/,/g,"")),
            SubCuentaVivienda:parseFloat(this.caso.ssv.replace(/,/g,"")),
            Bancos:bancos,
            PerfilCliente : this.caso.tasa,
            VersionOrigen:'Web rapida',
            GastoNotarial: this.caso.gastosNotarial,
            PrecalificacionTipo: this.caso.PrecalificacionTipo
          },
          objAportacion: {
            // para validacion en el metodo
            hasAportacion: this.hasAportacion,
            // para bd
            prepagoAportacion: this.tipoPrepago,
            plazoAportacion: this.tipoPlazo,
            montoAportacion: aportaVol,
            cantidadAportaciones: numAporte,
          },
        }
      }else if ((this.caso.destino == 1 || this.caso.destino == 5 || this.caso.destino == 2 )  && ( this.caso.programa == 6 || this.caso.programa == 1)) {
        objRequestRegistraCaso1 = {
          strApiKey: 'c7e0233e4a9c856d50662005a1c6cfbb',
          strMetodo: 'RegistraCaso',
          objCaso: {
            Id:id,
            Broker:'demoSoc@socasesores.com',
            ValorInmueble : parseFloat(this.caso.valorInmueble.replace(/,/g,"")),
            MontoSolicitado : parseFloat(this.caso.montoSolicitado.replace(/,/g,"")),
            Plazos : this.caso.plazos,
            Solicitante: 'solicitante@socasesores.com',
            Destino : this.caso.destino,
            Esquemas:this.caso.esquemas,
            Programas:[this.caso.programa],
            Bancos:bancos,
            PerfilCliente : this.caso.tasa,
            VersionOrigen:'Web rapida',
             GastoNotarial: this.caso.gastosNotarial,
						PrecalificacionTipo: this.caso.PrecalificacionTipo
            //MontoCredito: parseFloat(this.caso.montoCredito.replace(/,/g,""))
          },
          objAportacion: {
            // para validacion en el metodo
            hasAportacion: this.hasAportacion,
            // para bd
            prepagoAportacion: this.tipoPrepago,
            plazoAportacion: this.tipoPlazo,
            montoAportacion: aportaVol,
            cantidadAportaciones: numAporte,
          },
          objMinistraciones:{
              usoMinistraciones: this.usoMinistraciones,
              Plazo: this.plazoContruccion,
              Periodo: this.periodo,
              Porcentajes : porcentajes
            }
        }
      }else if ((this.caso.destino == 1 || this.caso.destino == 5 || this.caso.destino == 2 || this.caso.destino == 20)  && (this.caso.programa == 4 || this.caso.programa == 9 )) {
        objRequestRegistraCaso1 = {
          strApiKey: 'c7e0233e4a9c856d50662005a1c6cfbb',
          strMetodo: 'RegistraCaso',
          objCaso: {
            Id: id,
            ValorInmueble : parseFloat(this.caso.valorInmueble.replace(/,/g,"")),
            MontoSolicitado : 0,
            Plazos : this.caso.plazos,
            Solicitante: 'solicitante@socasesores.com',
            Broker:'demoSoc@socasesores.com',
            Destino : this.caso.destino,
            CreditoInfonavit:parseFloat(this.caso.creditoInf.replace(/,/g,"")),
            SubCuentaVivienda:parseFloat(this.caso.ssv.replace(/,/g,"")),
            //PlazoRestante:this.caso.plazoRestante,
            //PagoMensualActual: parseFloat(this.caso.pagoMensualActual.replace(/,/g,"")),
            Esquemas:this.caso.esquemas,
            Bancos:bancos,
            Programas:[this.caso.programa],
            PerfilCliente : this.caso.tasa,
            VersionOrigen:'Web rapida',
            MontoCredito: this.caso.montoCredito,
             GastoNotarial: this.caso.gastosNotarial,
						PrecalificacionTipo: this.caso.PrecalificacionTipo
            //ValorTerreno: parseFloat(this.caso.valorTerreno.replace(/,/g,"")),
						//ValorProyecto: parseFloat(this.caso.valorProyecto.replace(/,/g,"")),
          },
          objAportacion: {
            // para validacion en el metodo
            hasAportacion: this.hasAportacion,
            // para bd
            prepagoAportacion: this.tipoPrepago,
            plazoAportacion: this.tipoPlazo,
            montoAportacion: aportaVol,
            cantidadAportaciones: numAporte,
          },
          objMinistraciones:{
              usoMinistraciones: this.usoMinistraciones,
              Plazo: this.plazoContruccion,
              Periodo: this.periodo,
              Porcentajes : porcentajes
            }
        }
      } else if (this.autosDestinos.includes(this.caso.destino)) {
          // formato de plazos
          let plazos = this.caso.plazos;
          this.caso.plazos = [];
          
          plazos.forEach(plazo => {
            if (typeof plazo == 'object') {
              this.caso.plazos.push(parseInt(plazo.value));
            } else if (typeof plazo == 'string') {
              this.caso.plazos.push(parseInt(plazo));
            } else if([6,12,24,36,48,60,72,84,96,108,120].includes(plazo)) {
              this.caso.plazos.push(plazo);
            } else {
              let ps = 6;
              if (plazo > 0) {
                ps = plazo * 12;
              }
              this.caso.plazos.push(ps);
            }
          });

          objRequestRegistraCaso1 = {
            strApiKey: 'c7e0233e4a9c856d50662005a1c6cfbb',
            strMetodo: 'RegistraCasoAuto',
            objCaso: {
              Id: id,
              PrecalificacionTipo: this.caso.PrecalificacionTipo,
              Broker: 'demoSoc@socasesores.com',
              Destino: this.caso.destino,
              ValorVehiculo: parseFloat(this.caso.valorInmueble.replace(/,/g, '')),
              MontoEnganche: parseFloat(this.caso.montoSolicitado.replace(/,/g, '')),
              Anio: parseInt(this.caso.aniosACotizar),
              Plazos: this.caso.plazos,
              Solicitante: 'solicitante@socasesores.com',
              Esquemas: [2], // fijo
              Bancos: bancos,
              VersionOrigen: 'Web rapida',
            }
          }
          // console.log( 'AutomotrizUpdateCaso', objRequestRegistraCaso1 );
          // return;

        } else {
        objRequestRegistraCaso1 = {
          strApiKey: 'c7e0233e4a9c856d50662005a1c6cfbb',
          strMetodo: 'RegistraCaso',
          objCaso: {
            Id:id,
            Broker:'demoSoc@socasesores.com',
            ValorInmueble : parseFloat(this.caso.valorInmueble.replace(/,/g,"")),
            MontoSolicitado : parseFloat(this.caso.montoSolicitado.replace(/,/g,"")),
            Plazos : this.caso.plazos,
            Solicitante: 'solicitante@socasesores.com',
            Destino : this.caso.destino,
            Esquemas:this.caso.esquemas,
            Programas:[this.caso.programa],
            Bancos:bancos,
            PerfilCliente : this.caso.tasa,
            VersionOrigen:'Web rapida',
             GastoNotarial: this.caso.gastosNotarial,
						PrecalificacionTipo: this.caso.PrecalificacionTipo
          },
          objAportacion: {
            // para validacion en el metodo
            hasAportacion: this.hasAportacion,
            // para bd
            prepagoAportacion: this.tipoPrepago,
            plazoAportacion: this.tipoPlazo,
            montoAportacion: aportaVol,
            cantidadAportaciones: numAporte,
          },
          objMinistraciones:{
              usoMinistraciones: this.usoMinistraciones,
              Plazo: this.plazoContruccion,
              Periodo: this.periodo,
              Porcentajes : porcentajes
            }
        }
      }
      this.$axios.post('/',objRequestRegistraCaso1,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){

            if (this.caso.PrecalificacionTipo == 1) {
              this.id = response.data.objContenido.Id;
              this.callsaveNewEvent('cotización completada');

              this.registraAdicionales();
              this.cotiza(response.data.objContenido.Id, response.data.objContenido.Destino );
            } else if (this.caso.PrecalificacionTipo == 3) {
              this.id = response.data.objContenido.Precalificacion;
              this.callsaveNewEvent('cotización completada');

              this.registraAdicionales();
              this.cotiza(response.data.objContenido.Precalificacion, this.caso.destino );
            }

            if (localStorage.getItem('regreso')) {
              this.dameCaso();
            } else {
              this.dameCaso(true);
            } 
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en RegistraCaso',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }

      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    registrarCaso(){

      //Ministraciones

				let porcentajes = ''
        
        if (this.usoMinistraciones == 'Si') {
          porcentajes = this.camposID.name[1]

          for (let p = 2; p <= this.campos; p++) {													
            porcentajes = porcentajes + ',' + this.camposID.name[p]
          }
          porcentajes = porcentajes + ','
          	
        }else{
          this.plazoContruccion = ''
          this.periodo = ''	
          this.campos = ''				
        }
      
      // quitar comas numeros
      let aportaVol = '';
      let numAporte = '';
      if (this.hasAportacion == "Si") {

        // recalculo para casos especiales
        let plazo = Math.max(...this.caso.plazos);
        let periodo = this.tipoPlazo;
        let limiteAportacion = 0;

        // calcula el limite
        if (plazo > 0 && periodo > 0) {
          switch (periodo) {
            case 1:
              // mensual
              limiteAportacion = 12 * plazo;
              break;
            case 2:
              // bimestral
              limiteAportacion = (12 / 2) * plazo;
              break;
            case 3:
              // semestral
              limiteAportacion = (12 / 6) * plazo;
              break;
            case 4:
              // anual
              limiteAportacion = plazo;
              break;
          }
        }
        // limita el valor
        if ( this.numeroAportacion > limiteAportacion) {
          this.numeroAportacion = '';
          return; // No cotizar, por valores incorrectos
        }

        aportaVol = this.aportacionVoluntaria;
        numAporte = this.numeroAportacion;
        
        aportaVol = parseFloat(aportaVol.replaceAll(',', ''));

        if (typeof(numAporte) == "string") {
          numAporte = parseInt(numAporte.replaceAll(',', ''));
        }

      }

      let self=this
      this.items=[]
      this.$vs.loading({
        container: '#cotizacion' ,
        scale: 0.6
      })
      // console.log('Monto solicitado: '+this.caso.valorInmueble)
      // console.log('Monto solicitado: '+this.caso.montoCredito)
      // console.log('Destino: '+this.caso.destino)
       if(this.caso.montoCredito!==0 && this.caso.PrecalificacionTipo != 3){  //no usar replace cuando es 0
        var auxMontoCredito=parseFloat(this.caso.montoCredito.replace(/,/g,""))
        this.caso.montoCredito=auxMontoCredito
      //parseFloat(this.caso.montoCredito.replace(/,/g,""))
      }
      if (localStorage.getItem('caso')) {
        this.updateCaso(localStorage.getItem('caso'))
      }else {
        
        let objRequestRegistraCaso1= {}
        let bancos=[]
        for (var i = 0; i < this.caso.bancos.length; i++) {
          bancos.push(parseInt(this.caso.bancos[i].value))          
          this.bancos2.push(parseInt(this.caso.bancos[i].value))
        }        
        //if(this.caso.destino == '25' || this.caso.destino == '22' || this.caso.destino == '33' || this.caso.destino == '34'){
        // if(this.caso.destino == '19' || this.caso.destino == '25' || this.caso.destino == '22' || this.caso.destino == '33' || this.caso.destino == '34'){
        if((this.caso.destino == '19' || this.caso.destino == '25' || this.caso.destino == '22' || this.caso.destino == '33' || this.caso.destino == '34')  && ( this.caso.programa == 6 || this.caso.programa == 1) ){
          objRequestRegistraCaso1 = {
            strApiKey: 'c7e0233e4a9c856d50662005a1c6cfbb',
            strMetodo: 'RegistraCaso',
            objCaso: {
              Broker:'demoSoc@socasesores.com',
              ValorInmueble : parseFloat(this.caso.valorInmueble.replace(/,/g,"")),
              SaldoInsoluto : parseFloat(this.caso.montoSolicitado.replace(/,/g,"")),
              Plazos : this.caso.plazos,
              Solicitante: 'solicitante@socasesores.com',
              Destino : this.caso.destino,
              Esquemas:this.caso.esquemas,
              Programas:[this.caso.programa],
              PlazoRestante:this.caso.plazoRestante,
              PagoMensualActual: parseFloat(this.caso.pagoMensualActual.replace(/,/g,"")),
              Bancos:bancos,
              PerfilCliente : this.caso.tasa,
              VersionOrigen:'Web rapida',
              GastoNotarial: this.caso.gastosNotarial,
              PrecalificacionTipo: this.caso.PrecalificacionTipo
            },
            objAportacion: {
              // para validacion en el metodo
              hasAportacion: this.hasAportacion,
              // para bd
              prepagoAportacion: this.tipoPrepago,
              plazoAportacion: this.tipoPlazo,
              montoAportacion: aportaVol,
              cantidadAportaciones: numAporte,
            },
          }
        }else if((this.caso.destino == '19' || this.caso.destino == '25' || this.caso.destino == '22' || this.caso.destino == '33' || this.caso.destino == '34')  && ( this.caso.programa == 4 || this.caso.programa == 9) ){                  
        objRequestRegistraCaso1 = {
          strApiKey: 'c7e0233e4a9c856d50662005a1c6cfbb',
          strMetodo: 'RegistraCaso',
          objCaso: {            
            Broker:'demoSoc@socasesores.com',
            ValorInmueble : parseFloat(this.caso.valorInmueble.replace(/,/g,"")),
            SaldoInsoluto : parseFloat(this.caso.montoSolicitado.replace(/,/g,"")),
            Plazos : this.caso.plazos,
            Solicitante: 'solicitante@socasesores.com',
            Destino : this.caso.destino,
            Esquemas:this.caso.esquemas,
            Programas:[this.caso.programa],
            PlazoRestante:this.caso.plazoRestante,
            PagoMensualActual: parseFloat(this.caso.pagoMensualActual.replace(/,/g,"")),
            CreditoInfonavit:parseFloat(this.caso.creditoInf.replace(/,/g,"")),
            SubCuentaVivienda:parseFloat(this.caso.ssv.replace(/,/g,"")),
            Bancos:bancos,
            PerfilCliente : this.caso.tasa,
            VersionOrigen:'Web rapida',
            GastoNotarial: this.caso.gastosNotarial,
            PrecalificacionTipo: this.caso.PrecalificacionTipo
          },
          objAportacion: {
            // para validacion en el metodo
            hasAportacion: this.hasAportacion,
            // para bd
            prepagoAportacion: this.tipoPrepago,
            plazoAportacion: this.tipoPlazo,
            montoAportacion: aportaVol,
            cantidadAportaciones: numAporte,
          },
        }
      }else if ((this.caso.destino == 1 || this.caso.destino == 5 || this.caso.destino == 2 )  && ( this.caso.programa == 6 || this.caso.programa == 1)) {
          objRequestRegistraCaso1 = {
            strApiKey: 'c7e0233e4a9c856d50662005a1c6cfbb',
            strMetodo: 'RegistraCaso',
            objCaso: {
              //Id:id,
              Broker:'demoSoc@socasesores.com',
              ValorInmueble : parseFloat(this.caso.valorInmueble.replace(/,/g,"")),
              MontoSolicitado : parseFloat(this.caso.montoSolicitado.replace(/,/g,"")),
              Plazos : this.caso.plazos,
              Solicitante: 'solicitante@socasesores.com',
              Destino : this.caso.destino,
              Esquemas:this.caso.esquemas,
              Programas:[this.caso.programa],
              Bancos:bancos,
              PerfilCliente : this.caso.tasa,
              VersionOrigen:'Web rapida',
              //MontoCredito: parseFloat(this.caso.montoCredito.replace(/,/g,""))
              GastoNotarial: this.caso.gastosNotarial,
              PrecalificacionTipo: this.caso.PrecalificacionTipo
            },
            objAportacion: {
              // para validacion en el metodo
              hasAportacion: this.hasAportacion,
              // para bd
              prepagoAportacion: this.tipoPrepago,
              plazoAportacion: this.tipoPlazo,
              montoAportacion: aportaVol,
              cantidadAportaciones: numAporte,
            },
            objMinistraciones:{
              usoMinistraciones: this.usoMinistraciones,
              Plazo: this.plazoContruccion,
              Periodo: this.periodo,
              Porcentajes : porcentajes
            }
          }
          //console.log("ObjCaso: " + objCaso);
        }else if ((this.caso.destino == 1 || this.caso.destino == 5 || this.caso.destino == 2 || this.caso.destino == 20)  && (this.caso.programa == 4 || this.caso.programa == 9 )) {
          objRequestRegistraCaso1 = {
            // strApiKey: this.key,
            strApiKey: 'c7e0233e4a9c856d50662005a1c6cfbb', // incidencia broker
            strMetodo: 'RegistraCaso',
            objCaso: {
              //Id: id,
              ValorInmueble : parseFloat(this.caso.valorInmueble.replace(/,/g,"")),
              MontoSolicitado : 0,
              Plazos : this.caso.plazos,
              Solicitante: 'solicitante@socasesores.com',
              Broker:'demoSoc@socasesores.com',
              Destino : this.caso.destino,
              CreditoInfonavit:parseFloat(this.caso.creditoInf.replace(/,/g,"")),
              SubCuentaVivienda:parseFloat(this.caso.ssv.replace(/,/g,"")),
              //PlazoRestante:this.caso.plazoRestante,
              //PagoMensualActual: parseFloat(this.caso.pagoMensualActual.replace(/,/g,"")),
              Esquemas:this.caso.esquemas,
              Bancos:bancos,
              Programas:[this.caso.programa],              
              PerfilCliente : this.caso.tasa,
              VersionOrigen:'Web rapida',
              MontoCredito: this.caso.montoCredito,
              GastoNotarial: this.caso.gastosNotarial,
              PrecalificacionTipo: this.caso.PrecalificacionTipo
            },
            objAportacion: {
              // para validacion en el metodo
              hasAportacion: this.hasAportacion,
              // para bd
              prepagoAportacion: this.tipoPrepago,
              plazoAportacion: this.tipoPlazo,
              montoAportacion: aportaVol,
              cantidadAportaciones: numAporte,
            },
            objMinistraciones:{
              usoMinistraciones: this.usoMinistraciones,
              Plazo: this.plazoContruccion,
              Periodo: this.periodo,
              Porcentajes : porcentajes
            }            
          }          
        } else if (this.autosDestinos.includes(this.caso.destino)) {
          // formato de plazos
          let plazos = this.caso.plazos;
          this.caso.plazos = [];
          
          plazos.forEach(plazo => {
            if (typeof plazo == 'object') {
              this.caso.plazos.push(parseInt(plazo.value));
            } else if (typeof plazo == 'string') {
              this.caso.plazos.push(parseInt(plazo));
            } else {
              let ps = 6;
              if (plazo > 0) {
                ps = plazo * 12;
              }
              this.caso.plazos.push(ps);
            }
          });

          objRequestRegistraCaso1 = {
            strApiKey: 'c7e0233e4a9c856d50662005a1c6cfbb',
            strMetodo: 'RegistraCasoAuto',
            objCaso: {
              // Id: parseInt(this.id),
              Broker: 'demoSoc@socasesores.com',
              Destino: this.caso.destino,
              ValorVehiculo: parseFloat(this.caso.valorInmueble.replace(/,/g, '')),
              MontoEnganche: parseFloat(this.caso.montoSolicitado.replace(/,/g, '')),
              Anio: parseInt(this.caso.aniosACotizar),
              Plazos: this.caso.plazos,
              Solicitante: 'solicitante@socasesores.com',
              Esquemas: [2], // fijo
              Bancos: bancos,
              VersionOrigen: 'Web rapida',
              PrecalificacionTipo: this.caso.PrecalificacionTipo
            }
          }
          // console.log( 'AutomotrizNuevo', objRequestRegistraCaso1 );
          // return;

        } else {          
          objRequestRegistraCaso1 = {
            strApiKey: 'c7e0233e4a9c856d50662005a1c6cfbb',
            strMetodo: 'RegistraCaso',
            objCaso: {
              //Id:id,
              Broker:'demoSoc@socasesores.com',
              ValorInmueble : parseFloat(this.caso.valorInmueble.replace(/,/g,"")),
              MontoSolicitado : parseFloat(this.caso.montoSolicitado.replace(/,/g,"")),
              Plazos : this.caso.plazos,
              Solicitante: 'solicitante@socasesores.com',
              Destino : this.caso.destino,
              Esquemas:this.caso.esquemas,
              Programas:[this.caso.programa],
              Bancos:bancos,
              PerfilCliente : this.caso.tasa,
              VersionOrigen:'Web rapida',
              GastoNotarial: this.caso.gastosNotarial,
              PrecalificacionTipo: this.caso.PrecalificacionTipo
            },
            objAportacion: {
              // para validacion en el metodo
              hasAportacion: this.hasAportacion,
              // para bd
              prepagoAportacion: this.tipoPrepago,
              plazoAportacion: this.tipoPlazo,
              montoAportacion: aportaVol,
              cantidadAportaciones: numAporte,
            },
            objMinistraciones:{
              usoMinistraciones: this.usoMinistraciones,
              Plazo: this.plazoContruccion,
              Periodo: this.periodo,
              Porcentajes : porcentajes
            }
          }
        }

        this.$axios.post('/',objRequestRegistraCaso1,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
        .then(
          response => {
            if(response.data.intEstatus == 0){

              if (this.caso.PrecalificacionTipo == 1) {
                localStorage.setItem('caso', response.data.objContenido.Id);
                this.id=response.data.objContenido.Id;
                this.callsaveNewEvent('cotización completada');

                this.registraAdicionales();
                this.cotiza(response.data.objContenido.Id, response.data.objContenido.Destino);
                this.dameCaso(true);
              } else if (this.caso.PrecalificacionTipo == 3) {
                localStorage.setItem('caso', response.data.objContenido.Precalificacion);
                this.id = response.data.objContenido.Precalificacion;
                this.callsaveNewEvent('cotización completada');

                this.registraAdicionales();
                this.cotiza(response.data.objContenido.Precalificacion, this.caso.destino);
                this.dameCaso(true);
              }
              // localStorage.setItem('caso', response.data.objContenido.Id)
              // this.id=response.data.objContenido.Id
              // this.registraAdicionales()
              // this.cotiza(response.data.objContenido.Id, response.data.objContenido.Destino)
              // this.dameCaso(true)
            }else {
              this.$vs.notify({
                title:'Ocurrio un error en RegistraCaso',
                text:response.data.strError,
                color:'danger',
                position:'top-right'
              })
            }
          }

        ).catch(function (error) {
          self.$vs.notify({
            title:'Ocurrio un error de sistema',
            text:error,
            color:'danger',
            position:'top-right'
          })
        })
      }
    },
    async callsaveNewEvent(origen)
    {
      /**Comienza el registro de evento en la nueva cotizacion */
      var nombreNegocio='Hipotecario'
      let nombreU,VBrokerid
      let nombreC=JSON.parse(localStorage.getItem('userInfo')).Nombre
      if(nombreC == null){
        nombreU=''
      }
      else
      {
        nombreU=nombreC
      }
      let BrokerIdC=JSON.parse(localStorage.getItem('userInfo')).IdBrokerSupervisor
      let OrganizacionNombreS=JSON.parse(localStorage.getItem('userInfo')).OrganizacionNombre
      let ValorBien=parseFloat(this.caso.valorInmueble.replace(/,/g,""))
      let MontoSol =parseFloat(this.caso.montoSolicitado.replace(/,/g,""))
      let numLineaNegocio=this.caso.PrecalificacionTipo
      if(numLineaNegocio==3)
      {
        nombreNegocio='Auto'
      }
      if(BrokerIdC == null){
        VBrokerid=0
      }
      else
      {
        VBrokerid=BrokerIdC
      }
      await this.SaveNewEvent2('InsertarMedicionEventos',origen,nombreU,VBrokerid,OrganizacionNombreS,nombreNegocio,ValorBien,MontoSol);
    },

    async SaveNewEvent2(metodo,nameOrigen,nombreUs,NBrokerId,OrganizacionNombreS,LineaNeg,ValorBien,MontoSol)
    {
      
      
			var objRequestCount = {
				strApiKey: this.key,
				strMetodo: metodo,
        objEventos: {
                Origen: nameOrigen,
                nombreUsuario: nombreUs,
                IdBroker: NBrokerId,
                NombreOrganizacion: OrganizacionNombreS,
                valorBien:ValorBien,
                LineaNegocio:LineaNeg,
                MontoSolicitado:MontoSol
            },
			}

      await this.$axios.post('/',objRequestCount,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
			.then(
				response => {
					if(response.data.intEstatus == 0){
            console.log(response)
             
            }else {
            this.$vs.notify({
              title:'Ocurrio un error en guardar eventos',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
            console.log(response)
          }
				}
			).catch(function (error) {
        this.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
        
			})
    },
    filtrar(){
      var tipo = this.filtroModel
      switch(tipo) {
        case '1':
          this.items.sort((a,b) => (a.Mensualidad > b.Mensualidad) ? 1 : ((b.Mensualidad > a.Mensualidad) ? -1 : 0));
          this.filtroName= 'Mensualidad Inicial'
          this.filtroId=1
          break;
        case '2':
          this.items.sort((a,b) => (a.Cat > b.Cat) ? 1 : ((b.Cat > a.Cat) ? -1 : 0));
          this.filtroName= 'Cat'
          this.filtroId=2
          break;
        case '3':
          this.items.sort((a,b) => (a.Tasa > b.Tasa) ? 1 : ((b.Tasa > a.Tasa) ? -1 : 0));
          this.filtroName= 'Tasa'
          this.filtroId=3
          break;
        case '4':
          this.items.sort((a,b) => (a.PagoTotal > b.PagoTotal) ? 1 : ((b.PagoTotal > a.PagoTotal) ? -1 : 0));
          this.filtroName= 'Pago Total'
          this.filtroId=4
          break;
        case '5':
          this.items.sort((a,b) => (a.vctPromociones.length < b.vctPromociones.length) ? 1 : ((b.vctPromociones.length < a.vctPromociones.length) ? -1 : 0));
          this.filtroName= 'Promociones'
          this.filtroId=5
          break;
        case '6':
          this.items.sort((a,b) => (a.IngresoMinimo > b.IngresoMinimo) ? 1 : ((b.IngresoMinimo > a.IngresoMinimo) ? -1 : 0));
          this.filtroName= 'Ingreso Mínimo'
          this.filtroId=6
          break;
        case '7':
          this.items.sort((a,b) => (a.AhorroTotal < b.AhorroTotal) ? 1 : ((b.AhorroTotal < a.AhorroTotal) ? -1 : 0));
          this.filtroName= 'Ahorro Total'
          this.filtroId=7
          break;
        case '8':
          this.items.sort((a,b) => (a.MejoraMensualidad < b.MejoraMensualidad) ? 1 : ((b.MejoraMensualidad < a.MejoraMensualidad) ? -1 : 0));
          this.filtroName= 'Mejor Mensualidad'
          this.filtroId=8
          break;
        case '9':
					this.items.sort((a, b) => (a.Banco > b.Banco ? 1 : b.Banco > a.Banco ? -1 : 0))
					this.filtroName = 'Alfabético'
					this.filtroId = 9
					break
      }
    },
    getDestinos(strValue){
      let self=this
      var objRequestListaDestinos = {
        strApiKey: this.key,
        strMetodo: 'ListaDestinos',
      }
      this.$axios.post('/',objRequestListaDestinos,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            // let destinos=response.data.objContenido
            // for (var i = 0; i < destinos.length; i++) {
            //   if (destinos[i].Id != 31) {
            //     this.destinos.push(destinos[i])
            //   }
            // }
						let vctDestinos = response.data.objContenido;
						switch (strValue) {
							case 1:  // hipotecario, no muestra tipo automotriz ni pyme
								this.destinos = vctDestinos.filter(item => item.Tipo != 10 && item.Tipo != 11 && item.Id != 31);
								break;
							case 3: // auto, muestra solo automotriz
								this.destinos = vctDestinos.filter(item => item.Tipo == 10);
								break;
						}

						/**
						 * Si los destinos de la linea de negocio no tienen el destino actual se pone uno por defecto
						 * Hipotecario = asignar destino 1
						 * Auto = asignar destino 41
						 * 
						 * Se ejecuta antes de la asignacion de destino del componente Automotriz.vue
						 **/
						let searchDestino = this.destinos.find((item) => item.Id == this.caso.destino);
						if (!searchDestino) {
							if (this.caso.PrecalificacionTipo == 1) {
								this.caso.destino = 1;
							} else if (this.caso.PrecalificacionTipo == 3) {
								this.caso.destino = 41;
							}
						}

            // console.log( 'searchDestino', searchDestino );
            // console.log( 'searchDestin', this.caso.PrecalificacionTipo );
            // console.log( 'searchDestin', this.caso.destino );
          
						if (this.caso.PrecalificacionTipo == 1) {
							/**
							 * Se obtiene la lista de bancos completa
							 */
							this.bancos = []
							this.getBancos();
							// console.log( 'BancosHipotecario1', this.bancos );

						} else if (this.caso.PrecalificacionTipo == 3) {
							/**
							 * Solo listar bancos disponibles actualmente para autos, hacer dinamica despues
							 */

              //  this.dameBancosAuto()
              this.getBancosAutoDestino();

						/*	this.bancos = [];
							this.bancos = this.bancosAuto;
              
              // coincidencias de caso.bancos en bancoAuto
              let cloneCasoBancos = this.caso.bancos;
              this.caso.bancos = [];
              cloneCasoBancos.forEach(banco => {
                let s = this.bancosAuto.find(b => b.value == banco.value);
                if (s) {
                  this.caso.bancos.push(banco);
                }
              });*/
              // console.log( 'getDestinos', this.caso.bancos );
              // console.log( 'getDestinos', cloneCasoBancos );
              // console.log( 'getDestinos', this.bancosAuto );

              // console.log( 'BancosHipotecario3A', this.bancosAuto );
              // console.log( 'BancosHipotecario3B', bancoFilter );
						}

          }else {
            this.$vs.notify({
              title:'Ocurrio un error en ListaDestinos',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    dameBancosAuto(){
			if (this.caso.PrecalificacionTipo == 3) {
				let self = this
				let objRequestDamePlazos = {
				strApiKey: this.key,
				strMetodo: 'ListaBancosAutos'
				}
				this.$axios
				.post('/', objRequestDamePlazos, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
				.then((response) => {
					if (response.data.intEstatus == 0) {
					this.bancos = [];
					for (var i = 0; i < response.data.objContenido.length; i++) {
						this.bancos.push({ label: response.data.objContenido[i].Nombre, value: response.data.objContenido[i].Id })
					}
					this.caso.bancos = this.bancos
									
					}else {
					this.$vs.notify({
						title: 'Ocurrio un error en ListaBancosAutos',
						text: response.data.strError,
						color: 'danger',
						position: 'top-right',
					})
					}
				})
				.catch(function (error) {
					self.$vs.notify({
					title: 'Ocurrio un error de sistema',
					text: error,
					color: 'danger',
					position: 'top-right',
					})
				})
			}      
		},
    getProgramas(){
      let self=this
      var objRequestListaCasoEstatus = {
        strApiKey: this.key,
        strMetodo: 'ListaProgramas',
      }
      this.$axios.post('/',objRequestListaCasoEstatus,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            this.programas=response.data.objContenido
            this.todosProgramas=this.programas
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en ListaProgramas',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    getBancos(){
      if (this.id) {    
          this.DameCasoBancos()

      }else{             
        var data = {
        destino: this.caso.destino,
        esquemasACotizar: [{
          label:"Pago Fijo",
          value: 2
        }],
        plazosACotizar:[{
          label: "20 Años",
          value: 20
        }]            
      }           

      this.cambiaBancos(data)

      }
    },
    getTasas(){
      let self=this
      var objRequestListaEstados = {
        strApiKey: this.key,
        strMetodo: 'ListaPerfilesCliente',
      }
      this.$axios.post('/',objRequestListaEstados,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            this.tasas=response.data.objContenido
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en ListaPerfilesCliente',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    quitarFavorito(item){
      let self=this
      let objRequestFavoritoCaso = {
        strApiKey: this.key,
        strMetodo: 'NoFavorito',
        intVariante: item.Id,
        objCaso: {
         Id: this.id,
         IdBanco: item.BancoId,
         IdIon: item.IdIon
        }
      }
      this.$axios.post('/',objRequestFavoritoCaso,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if (response.data.intEstatus == 0) {
            this.dameCaso()
            // this.$vs.notify({
            //   title:`Eliminado exitosamente ${item.Id}`,
            //   text:`${item.Producto}`,
            //   position:'top-right',
            // })
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en NoFavorito',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    agregarFavorito (item) {
      
			const countFavoritos = this.caso.favoritos.length
			if ( countFavoritos >= 5 ) return

      let self=this
      let objRequestFavoritoCaso = {
        strApiKey: this.key,
        strMetodo: 'Favorito',
        intVariante: item.Id,
        objCaso: {
         Id: this.id,

        IdBanco: item.BancoId,
        IdIon: item.IdIon,
        intCat: item.Cat,
        ValorInmueble : this.caso.valorInmueble.replace(/,/g,""),
        MontoSolicitado: this.caso.montoSolicitado.replace(/,/g,""),
        intMensualidad: item.Mensualidad,
        intTasa: item.Tasa,
        intIngresoMinimo: item.IngresoMinimo,
        intEnganche: item.Enganche,
        intGastosIniciales: item.GastosIniciales,
        intAforo: item.Aforo,
        intPlazo: item.Plazo,
        intPagoTotal: item.PagoTotal,
        intMensualidadPromedio: item.MensualidadPromedio,
        intMensualidadInicial: item.MensualidadInicial,
        intEsquemas: item.Esquemas
        
        }
      }
      this.$axios.post('/',objRequestFavoritoCaso,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if (response.data.intEstatus == 0) {
            this.dameCaso()
            this.callsaveNewEvent('Favoritos')
            this.$vs.notify({
              title:'Agregado exitosamente',
              text:`${item.Producto}`,
              position:'top-right',
            })
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en NoFavorito',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    comprobarFavorito(item){

      if(item.BancoId==21 && this.caso.favoritos){
 
        for (var i = 0; i < this.caso.favoritos.length; i++) {

          if (this.caso.favoritos[i].BancoId == 21 && this.caso.favoritos[i].Mensualidad == item.Mensualidad && this.caso.favoritos[i].Tasa == item.Tasa  ) {           
                return false;
                

            }
        }       
      } else if(item.BancoId==33 && this.caso.favoritos){
 
        for (var i = 0; i < this.caso.favoritos.length; i++) {

          if (this.caso.favoritos[i].BancoId == 33 && this.caso.favoritos[i].Mensualidad == item.Mensualidad && this.caso.favoritos[i].Tasa == item.Tasa && this.caso.favoritos[i].Plazo == item.Plazo) {           
                return false;
                

            }
        }       
      }else{
         if (this.caso.favoritos) {
            for (var i = 0; i < this.caso.favoritos.length; i++) {
                if (this.caso.favoritos[i].Id == item.Id) {            
                    return false;

                }
            }
          }

      }
      
    
      return true;
    },
    dameCaso(flag){
      let self=this
      var objRequestDameCaso = {
        strApiKey: this.key,
        strMetodo: 'DameCaso',
        objCaso: {
         Id: this.id
        }
      }
      this.$axios.post('/',objRequestDameCaso,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            let cotizacion=response.data.objContenido;
            this.caso.favoritos=cotizacion.Favoritos
            this.caso.destino=cotizacion.Destino
            if (cotizacion.Programas.length) {
              this.caso.programa=cotizacion.Programas[0]
              this.Programa = cotizacion.Programas[0]
            }
            this.caso.estado=cotizacion.EstadoInmueble
            if (flag) {//alert(true)
              for (var i = 0; i < this.caso.favoritos.length; i++) {
                this.quitarFavorito(this.caso.favoritos[i])
              }
            }


           for (var i = 0; i < cotizacion.ComparaBancos.length; i++) {
							if (cotizacion.ComparaBancos[i].value) {
								this.bancos2.push(parseInt(cotizacion.ComparaBancos[i].value))
							} else {
								this.bancos2.push(parseInt(cotizacion.ComparaBancos[i]))
							}

						}

            this.caso.gastosNotarial= cotizacion.GastoNotarial


            if(localStorage.getItem('vistaNuevaCotizacion')){

            }

            this.caso.PrecalificacionTipo = cotizacion.PrecalificacionTipo ? cotizacion.PrecalificacionTipo : 1;
            this.caso.VersionOrigen = cotizacion.VersionOrigen;
/*
            // para obtener bancos seleccionados en auto
            if (this.caso.PrecalificacionTipo == 3) {
              // this.caso.bancos = this.bancos2;
              this.bancosAuto.forEach(banco => { 
                // si encuentra en lista de bancos autos una coincidencia la agrega (considera this.bancos2 sean valores numericos)
                if (this.bancos2.includes(banco.value)) {
                  this.caso.bancos.push(banco);
                }
              });
              // Por si acaso retiro duplicados
              let bancoFilter = this.caso.bancos.filter((item,index)=>{
                return this.caso.bancos.indexOf(item) === index;
              });
              this.caso.bancos = bancoFilter;
              console.log("dameCaso jor")
              console.log(this.caso.bancos)
            }*/

            // aportaciones
            this.hasAportacion = cotizacion.TieneAportacion;
            this.tipoPrepago = cotizacion.PrepagoAportacion;
            this.tipoPlazo = cotizacion.PlazoAportacion;
            this.aportacionVoluntaria = cotizacion.MontoAportacion;
            this.numeroAportacion = cotizacion.CantidadAportaciones;
            this.setFormatApoVol(); // formato para aportacion
            // aportaciones

            //Ministraciones
            this.dameMinistracion()
						this.validateFavoritos()

          }else {
            this.$vs.notify({
              title:'Ocurrio un error en DameCaso',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    DameCasoBancos(flag){
      let self=this
      var objRequestDameCaso = {
        strApiKey: this.key,
        strMetodo: 'DameCaso',
        objCaso: {
         Id: this.id
        }
      }
      this.$axios.post('/',objRequestDameCaso,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            let cotizacion=response.data.objContenido;            
            this.caso.destino=cotizacion.Destino            
            this.caso.plazos=cotizacion.Plazos
            this.caso.esquemas=cotizacion.Esquemas
           
						/**
						 * Si los destinos de la linea de negocio no tienen el destino actual se pone uno por defecto
						 * Hipotecario = asignar destino 1
						 * Auto = asignar destino 41
						 * 
						 * Se ejecuta antes de la asignacion de destino del componente Automotriz.vue
						 **/
						let searchDestino = this.destinos.find((item) => item.Id == this.caso.destino);
						if (!searchDestino) {
							if (this.caso.PrecalificacionTipo == 1) {
								this.caso.destino = 1;
							} else if (this.caso.PrecalificacionTipo == 3) {
								this.caso.destino = 41;
							}
						}


            this.obtenBancos();

            
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en DameCasoBancos',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    obtenBancos(){
      
      this.bancos = []

      let self = this
      let objRequestDamePlazos = {
        strApiKey: this.key,
        strMetodo: 'ListaBancosPlazos',
        objCaso: {
          Destino: this.caso.destino,
          Esquema: this.caso.esquemas,
          Plazos: this.caso.plazos,
        },
      }
      this.$axios
        .post('/', objRequestDamePlazos, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
        .then((response) => {
          if (response.data.intEstatus == 0) {
            if(this.caso.plazos.length==1){
            //if(e.plazosACotizar[0].value=='25'){
              if(this.caso.plazos[0]=='25'){
            
               this.caso.bancos=[]

                this.caso.bancos.push({ label: 'BANORTE', value: 4 })
                 this.caso.bancos.push({ label: 'HSBC', value: 6 })
               
                this.bancos.push({ label: 'BANORTE', value: 4 })
                this.bancos.push({ label: 'HSBC', value: 6 })
                
            }else{
              let bancos = response.data.objContenido
                for (var i = 0; i < bancos.length; i++) {
                  this.bancos.push({ label: bancos[i].Nombre, value: bancos[i].Id })
                }
                  this.bancos.push({ label: 'KREDI', value: 33 })
                 //this.caso.bancos = this.bancosEjecutivo //cambio bancos
                 this.caso.bancos = this.bancos                  
           }


           }else{
             if (this.caso.PrecalificacionTipo == 1) { // Condicione porque da problemas
               let bancos = response.data.objContenido
               for (var i = 0; i < bancos.length; i++) {
                 this.bancos.push({ label: bancos[i].Nombre, value: bancos[i].Id })
               }
                 this.bancos.push({ label: 'KREDI', value: 33 })
                //this.caso.bancos = this.bancosEjecutivo //cambio bancos
                this.caso.bancos = this.bancos 
              
             }
           }
           
            /**
            * Solo se mostrara el banco banorte si el plazo es 21,22,24 sin combinar con otros plazos
            */
            let checkPlazos = [];
            checkPlazos = this.caso.plazos;
            if (checkPlazos.length > 0 && checkPlazos.length < 4) { // solo puede tener 3 plazos max
              let findPlazo = this.caso.plazos.find(item => (item != 21 && item != 22 && item != 24));

              if (!findPlazo) { // si encontro otro plazo diferente a 21,22,24
                this.caso.bancos = [];
                this.bancos = [];
                this.caso.bancos.push({ label: 'BANORTE', value: 4 });
                this.bancos.push({ label: 'BANORTE', value: 4 });
              }
            }

          } else if(response.data.intEstatus == 1){
            this.caso.bancos = [];
          }else {
            this.$vs.notify({
              title: 'Ocurrio un error en ListaFinancieras',
              text: response.data.strError,
              color: 'danger',
              position: 'top-right',
            })
          }
        })
        .catch(function (error) {
          self.$vs.notify({
            title: 'Ocurrio un error en DamePlazos',
            text: error,
            color: 'danger',
            position: 'top-right',
          })
        })
    },
    openFavs(){
      window.open('/imprimir/'+this.id, '_blank');
    },
    getApikey(){
      if(!JSON.parse(localStorage.getItem('userInfo')).ApiKey){
        this.$router.push('/login');
      }else {
        this.key=JSON.parse(localStorage.getItem('userInfo')).ApiKey;
      }
    },
    dameInformacion(){
      let self=this
      let objRequestDameSolicitante = {
        strApiKey: this.key,
        strMetodo: 'MisDatos',
      }
      this.$axios.post('/',objRequestDameSolicitante,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            let data= response.data.objContenido
            this.emailEjecutivo=data.EMail
            this.editaCaso()
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    editaCaso(){

      // quitar comas numeros 2023-07-26
      let aportaVol = '';
      let numAporte = '';
      if (this.hasAportacion == "Si") {
        aportaVol = this.aportacionVoluntaria;
        numAporte = this.numeroAportacion;
        
        aportaVol = parseFloat(aportaVol.replaceAll(',', ''));

        if (typeof(numAporte) == "string") {
          numAporte = parseInt(numAporte.replaceAll(',', ''));
        }
      }

      let self= this
      var objRequestRegistraCaso1 = {
        strApiKey: this.key,//'a13768e0b29183037ac1f3acb68871bc',
        strMetodo: 'RegistraCaso',
        objCaso: {
          Id: this.id,
          Solicitante: this.email,
          Destino : this.caso.destino,
          Broker : this.emailEjecutivo,
        },
        objAportacion: {
          // para validacion en el metodo
          hasAportacion: this.hasAportacion,
          // para bd
          prepagoAportacion: this.tipoPrepago,
          plazoAportacion: this.tipoPlazo,
          montoAportacion: aportaVol,
          cantidadAportaciones: numAporte,
        },
      }
      this.$axios.post('/',objRequestRegistraCaso1,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            //alert('registrado')
            this.$vs.loading.close('#cotizacion > .con-vs-loading')
            $('#modalTabla').modal('show');
            localStorage.removeItem('caso')
            if(this.$store.state.AppActiveUser.TipoEjecutivoV3!='Inmo' ){
              $('#modalTabla').modal('show');
              $('#icono').html('').html('<svg xmlns="http://www.w3.org/2000/svg" width="70" height="70" fill="currentColor" class="bi bi-check-lg" viewBox="0 0 16 16"><path style="color:#079DEF" d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z"/></svg>');
              setTimeout(() => $('#modalTabla').modal('hide'), 10000);
              $("#modalTabla").on('hidden.bs.modal', function(){
                self.$router.push('/')              
              });
              // $('#modalTabla').on('hidden.bs.modal',this.$router.push('/').catch(() => {}));
              
            }
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en registraCaso',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
   /* curpValida(curp) {
     
        curp = curp.toUpperCase();
        this.curp = curp
        var re =  "[A-Z][A,E,I,O,U,X][A-Z]{2}[0-9]{2}[0-1][0-9][0-3][0-9][M,H][A-Z]{2}[B,C,D,F,G,H,J,K,L,M,N,Ñ,P,Q,R,S,T,V,W,X,Y,Z]{3}[0-9,A-Z][0-9]";
        var validado = curp.match(re);
      
        if (!validado){
          return false;
        }else{
          return true;
        } 
    },
    ValidaCurp(){

      
      if (this.curpValida(this.curp)) {
          document.getElementById('errorCurp').style.display='none'
          
        }else{
          document.getElementById('errorCurp').style.display='block'
         
        }
        
    },*/

    PreRegistroV3(){
      let self=this

       let objRequestCotizaCaso = {
      /////  AcreditModel: {
          PersonalMail: this.email,
          Phone: this.telefono,
          Name: this.nombre,
          SecondName: this.segundoNombre,
          LastName: this.apellidoPaterno,
          SecondLastName: this.apellidoMaterno,
          Status:'Referido',
          EmployeeId:this.SocGuid,

      //////  },
      }

      this.$axios.post('https://apivalidacionriesgos.sisec.mx/api/external/pre-accredited',objRequestCotizaCaso,{headers : this.header})
        .then(
          response => {
            this.$vs.loading({
                container: '#cotizacion',
                scale: 0.6
              }) 
            document.getElementById('btnGuardar').style.display='none' 
            
          }
        ).catch(function (error) {
          if(error.response.data.Message == "The CellPhone already exists"){
            mostrar = "El telefono ya se encuentra registrado intente con otro"
          }else if(error.response.data.Message == "The email already exists"){
            mostrar = "El correo elecronico ya se encuentra registrado intente con otro"
          }else{
            mostrar = error
          }
          
          self.$vs.notify({
            title:'Ocurrio un error en SISEC',
            text:mostrar,
            color:'danger',
            position:'top-right'
          })
        })


    },

    async GeneraLiga(){
      // token auth
      await this.getTokenSisec();
      let self=this
  
      var mostrar = ""
      let objRequestCotizaCaso = {
        AccreditedModel: {
          Name: this.nombre,
          SName: this.segundoNombre,
          LastName: this.apellidoPaterno,
          SecondLastName: this.apellidoMaterno,
          PersonalMail: this.email,
          CellPhone: this.telefono,
          CURP: this.curp,
          RFC: null,  
          CivilStatus: null,
          Nationality: null,
          Gender: null,
          Age: null,
          StudyGrade: null,
          EconomicDependents: null,
          NumberOfChildren: null,
          ChildrensAge: null,
          CP: null,
          MonetaryIncome: null,
          NumberOfCars: null,
          Activity: null,
          PhoneHome: null,
          DateBirtday: null,
          Cocredited: null,
          Weight: null,
          Height: null,
          NSS: null,
          TypeHousing: null,
          ResidenceTimeHome: null,
          ResidenceTimeTown: null,
          CompanyName: null,
          CompanyAddress: null,
          Position: null,
          TypeCompany: null,
          WorkingTime: null,
        },
        ReferencesModel: {
          Name: null,
          SName: null,
          LastName: null,
          SecondLastName: null,
          Phone: null,
          Relationship: null,
          TimeRelationship: null
        },
        CreditRequestModel: {
          TypeCredit: null,
          HomeValue: null,
          CreditAmount: null,
          Plazo: null
        },
        BrokerEmployeeModel: {
          Id: this.SocGuid,
          BrokerId: this.BrokerId,
          EmployeeName: null,
          EmployeeEmail: null,
          Origin: "Cotizador soc"
        },
        IsQuickQuote:true,
      }

      let tokenBearer = "Bearer " + this.tokenSisec;
      this.$axios.post('https://apivalidacionriesgos.sisec.mx/api/External/CreateAccredited',objRequestCotizaCaso,{headers : {"Authorization": tokenBearer}})
        .then(
          response => {
            this.$vs.loading({
                container: '#cotizacion',
                scale: 0.6
              }) 
            document.getElementById('btnGuardar').style.display='none' 
            this.IdLiga = response.data.AccreditedId
            this.registrarSolicitante()   
            this.EnviaCorreo()
            
          }
        ).catch(function (error) {
          if(error.response.data.Message == "The CellPhone already exists"){
            mostrar = "El telefono ya se encuentra registrado intente con otro"
          }else if(error.response.data.Message == "The email already exists"){
            mostrar = "El correo elecronico ya se encuentra registrado intente con otro"
          }else{
            mostrar = error
          }
          
          self.$vs.notify({
            title:'Ocurrio un error en SISEC',
            text:mostrar,
            color:'danger',
            position:'top-right'
          })
        })
    },
    DameCredenciales(){
      let self=this
      let objRequestDameCredenciales = {
        strApiKey: this.key,
        strMetodo: 'DameCredenciales',
        objBroker: {
          ApiKey: this.key
        }
      }
      this.$axios.post('/',objRequestDameCredenciales,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          this.SocGuid = response.data.objContenido.SocGuid
          this.BrokerId = response.data.objContenido.BrokerId

          //this.SocGuid = 'f5b58eb5-4f2e-49c1-ae90-0fcab572c879';
          //this.BrokerId = 'f5b58eb5-4f2e-49c1-ae90-0fcab572c879';
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    EnviaCorreo(){
      let self=this
      let objRequestEnviaLigaCorreo = {
        Nombre: this.nombre,
        Id: this.IdLiga,
        Correo: this.email
      }

      this.$vs.notify({
        title:'Enviando Correo',
        position:'top-right'
      })

      this.$axios.post('https://cotizador.socasesores.com/admin/formatos/emailComparaleProd/EmailLigaCliente.php',objRequestEnviaLigaCorreo,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
        .then(
          response => {

            this.$vs.notify({
              title:'Se envió correctamente el correo electrónico a',
              text:this.email,
              position:'top-right'
            })

          }
        ).catch(function (error) {
          self.$vs.notify({
            title:'Ocurrio un error de sistema',
            text:error,
            color:'danger',
            position:'top-right'
          })
        })
    },
  }
}
</script>

<style lang="css" scoped>
.filtros {
	display: -webkit-box !important;
	flex-wrap: inherit !important;
}
.favoritos {
	border: none !important;
	background: transparent !important;
}
th {
	font-size: 14px;
}
.w2-full {
	padding-top: 0px !important;
	width: 100%;
}
.vx-card.arriba {
	margin-top: -860px;
	padding-top: 15px;
	z-index: 777;
	min-height: 900px;
}
.btn-fav {
	float: right !important;
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
	margin-right: 30px !important;
}

.router-view {
	padding-top: 25rem !important;
}

.card__header{
  border:none !important;
  margin-left: -10px;
}

.liga_cliente{
	background:white; 
	border-radius: 1.5%;	
	margin-top:40px;
	margin-bottom: 20px;
}

.liga_cliente .row{
  font-family: Arial, Helvetica, sans-serif;
  color: black;
}

.vs-ordenar { 
  max-width: 180px !important;
}

@media screen and (max-width: 915px) {
	.btn-fav {
		align-content: center;
		justify-content: center;
		display: flex;
		background-color: #fff;
		color: #40516f;
		border-radius: 5px;
		border: #e0e0e0 solid 1px;
		min-width: 100%;
		max-width: 100%;
		margin-right: 0px !important;
		margin-left: auto;
		float: none !important;
	}

	.w2-full {
		width: auto;
	}
}


/* aportacion voluntaria */
.symbol-currency {
	display: inline-block;
	position: relative;
}
.symbol-currency:before {
	content: "$";
	position: absolute;
	left: 8px;
	top: 60%;
	transform: translateY(-50%);
	z-index: 101;
}
.input-currency {
	padding-left: 20px;
}
</style>